import DataDS from '@api/domain/ds/DataDS'
import DataRepo from '@api/domain/repo/DataRepo'

import {
  GetRawMaterialsType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  GetRawMaterialWarehouseType,
  DeleteRawMaterialType,
  DeleteRawMaterialsType,
  GetRawMaterialsWarehouseType,
} from '@customTypes/rawMaterial'
import {
  CreateReceiptType,
  DeleteRawMaterialsFromReceiptType,
  GetReceiptsType,
  UpdateReceiptType,
} from '@customTypes/receipt'
import {
  GetWarehousesType,
  CreateWarehouseType,
  UpdateWarehouseType,
  MoveRawMaterialsType,
  DeleteRawMaterialsFromWarehouseType,
} from '@customTypes/warehouse'
import { CreateCommitType, GetCommitsType, UpdateCommitType } from '@customTypes/commit'
import {
  CreateBlockType,
  DeleteReceiptsBlockType,
  GetBlocksType,
  UpdateBlockType,
} from '@customTypes/block'
import { CreateClientType, GetClientsType, UpdateClientType } from '@customTypes/client'
import { CreateProformaType, UpdateProformaType } from '@customTypes/proforma'
import { SignInType, SignUpType } from '@customTypes/user'
import {
  CreateEventType,
  DeleteEventsType,
  GetEventsType,
  UpdateEventType,
} from '@customTypes/event'
import { CreateGeoCheckpointType, GetGeoCheckpointType } from '@customTypes/geoCheckpoint'

class DataRepoImpl extends DataRepo {
  constructor(private db: DataDS) {
    super()
  }

  async getUser() {
    const data = await this.db.getUser()
    return data
  }

  async getUsers() {
    const data = await this.db.getUsers()
    return data
  }

  async signinWithEmailAndPassword(params: SignInType) {
    const data = await this.db.signinWithEmailAndPassword(params)
    return data
  }

  async signUpWithEmailAndPassword(params: SignUpType) {
    const data = await this.db.signUpWithEmailAndPassword(params)
    return data
  }

  async logout() {
    await this.db.logout()
  }

  async deleteAccount() {
    await this.db.deleteAccount()
  }

  async saveUserGeoCheckpoint(params: CreateGeoCheckpointType) {
    await this.db.saveUserGeoCheckpoint(params)
  }

  async getGeoCheckpoints(params: GetGeoCheckpointType) {
    const data = await this.db.getGeoCheckpoints(params)
    return data
  }

  async checkBiometric(): Promise<boolean> {
    const data = await this.db.checkBiometric()
    return data
  }

  //Raw material
  async downloadAttachment(url: string, filename: string) {
    await this.db.downloadAttachment(url, filename)
  }

  async getRawMaterials(params: GetRawMaterialsType) {
    const data = await this.db.getRawMaterials(params)
    return data
  }

  async getRawMaterialById(params: GetRawMaterialWarehouseType) {
    const data = await this.db.getRawMaterialById(params)
    return data
  }

  async getRawMaterialBaseById(id: string) {
    const data = await this.db.getRawMaterialBaseById(id)
    return data
  }

  async createRawMaterial(data: CreateRawMaterialBaseType) {
    const response = await this.db.createRawMaterial(data)
    return response
  }

  async updateRawMaterial(data: UpdateRawMaterialBaseType) {
    const response = await this.db.updateRawMaterial(data)
    return response
  }

  async deleteRawMaterial(params: DeleteRawMaterialType) {
    await this.db.deleteRawMaterial(params)
  }

  async deleteRawMaterials(params: DeleteRawMaterialsType) {
    await this.db.deleteRawMaterials(params)
  }

  //Warehouse
  async getWarehouses(params: GetWarehousesType) {
    const data = await this.db.getWarehouses(params)
    return data
  }

  async getWarehouseById(id: string) {
    const data = await this.db.getWarehouseById(id)
    return data
  }

  async getMaterialsByWarehouse(params: GetRawMaterialsWarehouseType) {
    const data = await this.db.getMaterialsByWarehouse(params)
    return data
  }

  async createWarehouse(data: CreateWarehouseType) {
    const response = await this.db.createWarehouse(data)
    return response
  }

  async updateWarehouse(data: UpdateWarehouseType) {
    await this.db.updateWarehouse(data)
  }

  async deleteWarehouse(id: string) {
    await this.db.deleteWarehouse(id)
  }

  async deleteWarehouses(ids: string[]) {
    await this.db.deleteWarehouses(ids)
  }

  async moveRawMaterials(params: MoveRawMaterialsType) {
    await this.db.moveRawMaterials(params)
  }

  async deleteRawMaterialsFromWarehouse(params: DeleteRawMaterialsFromWarehouseType) {
    await this.db.deleteRawMaterialsFromWarehouse(params)
  }

  //Commits
  async getCommits(params: GetCommitsType) {
    const data = await this.db.getCommits(params)
    return data
  }

  async getCommitById(id: string) {
    const data = await this.db.getCommitById(id)
    return data
  }

  async createCommit(data: CreateCommitType) {
    const response = await this.db.createCommit(data)
    return response
  }

  async updateCommit(data: UpdateCommitType) {
    await this.db.updateCommit(data)
  }

  async deleteCommit(id: string) {
    await this.db.deleteCommit(id)
  }

  async deleteCommits(ids: string[]) {
    await this.db.deleteCommits(ids)
  }

  //Receipts
  async getReceipts(params: GetReceiptsType) {
    const data = await this.db.getReceipts(params)
    return data
  }

  async getReceiptsExtended(params: GetReceiptsType) {
    const data = await this.db.getReceiptsExtended(params)
    return data
  }

  async getReceiptById(id: string) {
    const data = await this.db.getReceiptById(id)
    return data
  }

  async createReceipt(data: CreateReceiptType) {
    const response = await this.db.createReceipt(data)
    return response
  }

  async updateReceipt(data: UpdateReceiptType) {
    await this.db.updateReceipt(data)
  }

  async deleteReceipt(id: string) {
    await this.db.deleteReceipt(id)
  }

  async deleteReceipts(ids: string[]) {
    await this.db.deleteReceipts(ids)
  }

  async deleteRawMaterialsFromReceipt(params: DeleteRawMaterialsFromReceiptType) {
    const data = await this.db.deleteRawMaterialsFromReceipt(params)
    return data
  }

  //Blocks
  async getBlocks(params: GetBlocksType) {
    const data = await this.db.getBlocks(params)
    return data
  }

  async getBlocksExtended(params: GetBlocksType) {
    const data = await this.db.getBlocksExtended(params)
    return data
  }

  async getBlockById(id: string) {
    const data = await this.db.getBlockById(id)
    return data
  }

  async createBlock(data: CreateBlockType) {
    const response = await this.db.createBlock(data)
    return response
  }

  async updateBlock(data: UpdateBlockType) {
    await this.db.updateBlock(data)
  }

  async deleteBlock(id: string) {
    await this.db.deleteBlock(id)
  }

  async deleteBlocks(ids: string[]) {
    await this.db.deleteBlocks(ids)
  }

  async deleteReceiptsFromBlock(params: DeleteReceiptsBlockType) {
    await this.db.deleteReceiptsFromBlock(params)
  }

  //Clients
  async getClients(params: GetClientsType) {
    const data = await this.db.getClients(params)
    return data
  }

  async getClientById(id: string) {
    const data = await this.db.getClientById(id)
    return data
  }

  async createClient(data: CreateClientType) {
    const response = await this.db.createClient(data)
    return response
  }

  async updateClient(data: UpdateClientType) {
    await this.db.updateClient(data)
  }

  async deleteClient(id: string) {
    await this.db.deleteClient(id)
  }

  async deleteClients(ids: string[]) {
    await this.db.deleteClients(ids)
  }

  async getEvents(params: GetEventsType) {
    const data = await this.db.getEvents(params)
    return data
  }

  async createEvent(data: CreateEventType) {
    const response = await this.db.createEvent(data)
    return response
  }

  async updateEvent(data: UpdateEventType) {
    await this.db.updateEvent(data)
  }

  async deleteEvent(id: string) {
    await this.db.deleteEvent(id)
  }

  async deleteEvents(params: DeleteEventsType) {
    await this.db.deleteEvents(params)
  }

  //Proformas
  async getProformas(params: GetClientsType) {
    const data = await this.db.getProformas(params)
    return data
  }

  async getProformaById(id: string) {
    const data = await this.db.getProformaById(id)
    return data
  }

  async createProforma(data: CreateProformaType) {
    const response = await this.db.createProforma(data)
    return response
  }

  async updateProforma(data: UpdateProformaType) {
    await this.db.updateProforma(data)
  }

  async deleteProforma(id: string) {
    await this.db.deleteProforma(id)
  }

  async deleteProformas(ids: string[]) {
    await this.db.deleteProformas(ids)
  }
}

export default DataRepoImpl
