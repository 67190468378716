import React from 'react'
import { Loader as LoaderCore } from '@mantine/core'

import { $ } from '@utils/styles'

type LoaderProps = {
  className?: string
  children?: React.ReactNode
}

const LoaderText = (props: LoaderProps) => {
  const { className, children } = props
  return (
    <div
      className={$(
        'cd-w-full cd-h-full cd-flex cd-items-center cd-justify-center cd-flex-col cd-gap-y-[1rem]',
        className,
      )}
    >
      <LoaderCore color="blue" type="dots" />
      {children}
    </div>
  )
}

export default LoaderText
