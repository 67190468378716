import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { sha256 } from 'crypto-hash'
import { type FirebaseApp, type FirebaseError, initializeApp } from 'firebase/app'

import {
  type Auth,
  updateProfile,
  initializeAuth,
  onAuthStateChanged,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  browserPopupRedirectResolver,
  createUserWithEmailAndPassword,
} from 'firebase/auth'

import {
  type Firestore,
  doc,
  limit,
  query,
  where,
  getDoc,
  setDoc,
  getDocs,
  orderBy,
  updateDoc,
  deleteDoc,
  collection,
  writeBatch,
  startAfter,
  getFirestore,
  runTransaction,
  QueryConstraint,
  DocumentReference,
  initializeFirestore,
  DocumentSnapshot,
} from 'firebase/firestore'

import {
  type FirebaseStorage,
  type StorageReference,
  ref,
  getBlob,
  getStorage,
  getMetadata,
  uploadString,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage'

import type { SignInType, SignUpType, UserType } from '@customTypes/user'

import DataDS from '@api/domain/ds/DataDS'

import { Logger } from '@utils/log'
import { ErrorCodes, ErrorService } from '@utils/error'
import { Collections, GEOAPIFY_URL } from '@constants/api'
import {
  GetRawMaterialsType,
  RawMaterialBaseType,
  DeleteRawMaterialType,
  DeleteRawMaterialsType,
  RawMaterialFirebaseType,
  RawMaterialWarehouseType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  GetRawMaterialWarehouseType,
  GetRawMaterialsWarehouseType,
} from '@customTypes/rawMaterial'

import {
  CommitType,
  GetCommitsType,
  CommitBaseType,
  CreateCommitType,
  UpdateCommitType,
  CommitFirebaseType,
} from '@customTypes/commit'

import {
  WarehouseType,
  GetWarehousesType,
  WarehouseBaseType,
  CreateWarehouseType,
  UpdateWarehouseType,
  MoveRawMaterialsType,
  WarehouseFirebaseType,
  DeleteRawMaterialsFromWarehouseType,
} from '@customTypes/warehouse'

import { StockBaseType } from '@customTypes/stock'

import {
  ReceiptType,
  ReceiptBaseType,
  GetReceiptsType,
  UpdateReceiptType,
  ReceiptsFirebaseType,
  RawMaterialReceiptType,
  DeleteRawMaterialsFromReceiptType,
  CreateReceiptType,
} from '@customTypes/receipt'
import { QtyReceiptBaseType } from '@customTypes/qtyReceipt'
import {
  BlockBaseType,
  BlockFirebaseType,
  BlockType,
  CreateBlockType,
  DeleteReceiptsBlockType,
  GetBlocksType,
  ReceiptBlockType,
  UpdateBlockType,
} from '@customTypes/block'
import { QtyBlockBaseType } from '@customTypes/qtyBlock'
import {
  CreateClientType,
  GetClientsType,
  ClientBaseType,
  ClientFirebaseType,
  UpdateClientType,
  ClientType,
} from '@customTypes/client'
import {
  CreateProformaType,
  CustomerProformaType,
  GetProformasType,
  ProformaBaseType,
  ProformaFirebaseType,
  SequentialProformaType,
  UpdateProformaType,
} from '@customTypes/proforma'
import queryClient from '@api/datasource/query'
import QueryKeys from '@constants/queryKeys'
import { QueryKey } from '@tanstack/react-query'
import {
  CreateGeoCheckpointType,
  GeoAPIfyType,
  GeoCheckpointBaseType,
  GeoCheckpointFirebaseType,
  GeoPropertiesType,
} from '@customTypes/geoCheckpoint'
import {
  CreateEventType,
  DeleteEventsType,
  EventBaseType,
  EventFirebaseType,
  GetEventsType,
  UpdateEventType,
} from '@customTypes/event'

const ConfigCredentials = {
  firebaseConfig: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  },
}

const SkipEmails = ['jverduga@seinproec.com', 'enmanuelmag@gmail.com']

const DEVICE_ID_KEY = 'device-id-seinpro'

const USER_ROLE_KEY = 'user-role-seinpro'

class FirebaseDS extends DataDS {
  db: Firestore
  private auth: Auth
  private app: FirebaseApp
  private storage: FirebaseStorage

  constructor() {
    super()
    this.app = initializeApp(ConfigCredentials.firebaseConfig)
    this.db = this.buildFirestore()
    this.storage = getStorage(this.app)
    this.auth = initializeAuth(this.app, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: browserPopupRedirectResolver,
    })
  }

  buildFirestore() {
    try {
      return initializeFirestore(this.app, {
        ignoreUndefinedProperties: true,
        experimentalForceLongPolling: false,
      })
    } catch (error) {
      return getFirestore(this.app)
    }
  }

  async getDeviceID() {
    try {
      const { userAgent, hardwareConcurrency } = window.navigator
      const { height, width, pixelDepth, colorDepth } = window.screen

      const info = `${userAgent}-${hardwareConcurrency}-${height}-${width}-${pixelDepth}-${colorDepth}`

      return await sha256(info)
    } catch (error) {
      Logger.error('Error getting device id', error)
      return 'dummy-device-id'
    }
  }

  async checkDeviceID(user: UserType) {
    if (user.deviceID && user.deviceID !== 'dummy-device-id') {
      return user
    }

    const deviceID = await this.getDeviceID()

    const userRef = doc(this.db, Collections.USERS.NAME, user.uid)

    const newUserData: UserType = {
      ...user,
      deviceID,
    }

    await updateDoc(userRef, {
      deviceID,
    })

    return newUserData
  }

  async signinWithEmailAndPassword(params: SignInType) {
    try {
      const { email, password } = params
      const { user: userSDK } = await signInWithEmailAndPassword(
        this.auth,
        email.toLowerCase().trim(),
        password,
      )

      const userRef = doc(this.db, Collections.USERS.NAME, userSDK.uid)

      const userTemp = await this.parseRefToEntity<UserType>(userRef)

      const userData = await this.checkDeviceID(userTemp)

      const user: UserType = {
        uid: userSDK.uid,
        role: userData.role,
        email: userSDK.email || '',
        deviceID: userData.deviceID,
        displayName: userSDK.displayName || '',
      }

      localStorage.setItem(DEVICE_ID_KEY, user.deviceID)

      localStorage.setItem(USER_ROLE_KEY, user.role)

      return user
    } catch (error) {
      Logger.error('Error login with email and password', error)

      const errorFirebase = error as FirebaseError
      if (errorFirebase.code === 'auth/user-not-found') {
        throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
      } else if (errorFirebase.code === 'auth/invalid-credential') {
        throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
      } else {
        throw ErrorService.get(ErrorCodes.ERROR_LOGIN_WITH_EMAIL_AND_PASSWORD)
      }
    }
  }

  async signUpWithEmailAndPassword(params: SignUpType) {
    try {
      const { email, password, displayName } = params

      const { user: userSDK } = await createUserWithEmailAndPassword(
        this.auth,
        email.toLowerCase().trim(),
        password,
      )

      updateProfile(userSDK, {
        displayName,
      })

      const deviceID = await this.getDeviceID()

      const user: UserType = {
        uid: userSDK.uid,
        deviceID,
        displayName,
        role: 'user',
        email: userSDK.email || '',
      }

      //save user on database
      const userRef = doc(this.db, Collections.USERS.NAME, user.uid)

      localStorage.setItem(DEVICE_ID_KEY, deviceID)

      localStorage.setItem(USER_ROLE_KEY, user.role)

      await setDoc(userRef, user)

      return user
    } catch (error) {
      Logger.error('Error register with email and password', error)

      throw ErrorService.get(ErrorCodes.ERROR_REGISTER_WITH_EMAIL_AND_PASSWORD)
    }
  }

  async getUsers() {
    try {
      const collectionRef = collection(this.db, Collections.USERS.NAME)

      const usersSnap = await getDocs(collectionRef)

      const usersData = usersSnap.docs.map((doc) => doc.data()) as UserType[]

      return usersData
    } catch (error) {
      Logger.error('Error getting users', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_USERS)
    }
  }

  async getUser() {
    try {
      const firebaseUser = this.auth.currentUser

      const deviceID = localStorage.getItem(DEVICE_ID_KEY) ?? ''

      const role = localStorage.getItem(USER_ROLE_KEY) as 'admin' | 'user'

      if (firebaseUser) {
        const user: UserType = {
          deviceID,
          displayName: firebaseUser.displayName || '',
          email: firebaseUser.email || '',
          role,
          uid: firebaseUser.uid,
        }

        return user
      }

      const currentUser = await new Promise<UserType | null>((resolve, reject) =>
        onAuthStateChanged(
          this.auth,
          (user) => {
            if (!user) return resolve(null)

            return resolve({
              uid: user.uid,
              email: user.email || '',
              displayName: user.displayName || '',
              deviceID,
              role,
            })
          },
          (error) => reject(error),
        ),
      )

      return currentUser
    } catch (error) {
      Logger.error('Error getting user from Firebase', error)
      throw ErrorService.get(ErrorCodes.ERROR_SESSION_EXPIRED)
    }
  }

  async logout() {
    try {
      await this.auth.signOut()
    } catch (error) {
      Logger.error('Error logout', error)
      throw ErrorService.get(ErrorCodes.ERROR_LOGOUT)
    }
  }

  async deleteAccount() {
    try {
      const user = this.auth.currentUser

      if (!user) {
        Logger.error('User not found')
        throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
      }

      await user.delete()
    } catch (error) {
      Logger.error('Error deleting account', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_ACCOUNT)
    }
  }

  async saveUserGeoCheckpoint(params: CreateGeoCheckpointType) {
    if (!navigator.geolocation) {
      throw ErrorService.get(ErrorCodes.ERROR_GEOLOCATION_NOT_AVAILABLE)
    }
    const user = await this.getUser()

    if (!user) {
      throw ErrorService.get(ErrorCodes.ERROR_USER_NOT_FOUND)
    }

    try {
      const { attachment, message } = params

      const userFirebase = await this.parseRefToEntity<UserType>(
        doc(this.db, Collections.USERS.NAME, user.uid),
      )

      if (user.deviceID !== userFirebase.deviceID && !SkipEmails.includes(user.email)) {
        throw ErrorService.get(ErrorCodes.ERROR_DEVICE_ID_CHANGED)
      }

      const position = await new Promise<GeolocationPosition>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject),
      )

      const geoURL = new URL(GEOAPIFY_URL)

      geoURL.searchParams.append('lat', position.coords.latitude.toString())
      geoURL.searchParams.append('lon', position.coords.longitude.toString())
      geoURL.searchParams.append('apiKey', import.meta.env.VITE_GEOAPIFY_KEY)

      const result = await fetch(geoURL.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })

      const geoData = (await result.json()) as GeoAPIfyType

      let properties: GeoPropertiesType | null = null

      if (geoData.features.length) {
        const [feature] = geoData.features
        const { name, country, state, city, street, suburb } = feature.properties

        properties = {
          name,
          country,
          state,
          city,
          street,
          suburb,
        }
      }

      const currentTimestamp = moment().unix()

      const attachmentPath = await this.uploadAttachment('geoCheckpoint', user.uid, attachment)

      const geoCheckpoint: GeoCheckpointFirebaseType = {
        uid: uuidv4(),
        user: this.parseEntitytoRef(user),
        message,
        deviceID: user.deviceID,
        attachment: attachmentPath,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
        location: {
          accuracy: position.coords.accuracy,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed || -1,
          altitude: position.coords.altitude || -1,
          properties,
        },
      }

      const geoCheckpointRef = doc(this.db, Collections.GEO_CHECKPOINTS.NAME, geoCheckpoint.uid)

      await setDoc(geoCheckpointRef, geoCheckpoint)
    } catch (error) {
      Logger.error('Error saving user geo checkpoint', error)
      throw error
    }
  }

  async getGeoCheckpoints() {
    try {
      const collectionRef = collection(this.db, Collections.GEO_CHECKPOINTS.NAME)

      const q = query(collectionRef, orderBy('createdAt', 'desc'))

      const geoCheckpointsSnap = await getDocs(q)

      const geoCheckpointsFirebaseData = geoCheckpointsSnap.docs.map((doc) =>
        doc.data(),
      ) as GeoCheckpointFirebaseType[]

      const geoCheckpointsData: GeoCheckpointBaseType[] = []

      for (const geoCheckpoint of geoCheckpointsFirebaseData) {
        const user = await this.parseRefToEntity<UserType>(geoCheckpoint.user)

        const geoCheckpointData: GeoCheckpointBaseType = {
          uid: geoCheckpoint.uid,
          user: {
            uid: user.uid,
            displayName: user.displayName,
            deviceID: user.deviceID,
            email: user.email,
            role: user.role,
          },
          deviceID: user.deviceID,
          message: geoCheckpoint.message,
          attachment: geoCheckpoint.attachment,
          location: geoCheckpoint.location,
          createdAt: geoCheckpoint.createdAt,
          updatedAt: geoCheckpoint.updatedAt,
        }

        geoCheckpointsData.push(geoCheckpointData)
      }

      return geoCheckpointsData
    } catch (error) {
      Logger.error('Error getting geo checkpoints', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_GEO_CHECKPOINTS)
    }
  }

  async checkBiometric() {
    try {
      const user = this.auth.currentUser

      if (!user) {
        Logger.error('User not found')
        throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
      }

      return true
    } catch (error) {
      Logger.error('Error checking biometric', error)
      throw error
      //throw ErrorService.get(ErrorCodes.ERROR_CHECKING_BIOMETRIC)
    }
  }

  async getId(collectionData: (typeof Collections)[keyof typeof Collections]) {
    try {
      const collectionRef = collection(this.db, collectionData.NAME)

      //get the last id (last updated)
      const q = query(collectionRef, orderBy('uid', 'desc'), limit(10))

      const snapshot = await getDocs(q)

      if (snapshot.empty) {
        return `${collectionData.ID}1`
      }

      const lastDoc = snapshot.docs[0]

      const count = parseInt(lastDoc.id.replace(collectionData.ID, ''))

      return `${collectionData.ID}${count + 1}`
    } catch (error) {
      Logger.error('Error getting id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_ID)
    }
  }

  async getURLAttachment(storageRef: StorageReference) {
    try {
      return await getDownloadURL(storageRef)
    } catch (error) {
      Logger.error('Error getting attachment', error)
    }
  }

  async existsAttachment(attachmentPath: string) {
    const fileRef = ref(this.storage, attachmentPath)

    try {
      await getDownloadURL(fileRef)

      return true
    } catch (error) {
      return false
    }
  }

  parseEntitytoRef<T extends { uid: string }>(user: T) {
    const userRef = doc(this.db, Collections.USERS.NAME, user.uid)
    return userRef
  }

  async parseRefToEntity<T>(ref: DocumentReference) {
    const userSnap = await getDoc(ref)

    return userSnap.data() as T
  }

  async deleteAttachments(attachmentsPath: string[]) {
    try {
      for (const attachment of attachmentsPath) {
        const fileRef = ref(this.storage, attachment)

        await deleteObject(fileRef)
      }
    } catch (error) {
      Logger.error('Error deleting attachments', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_ATTACHMENTS)
    }
  }

  parseFieldsBase64(base64: string) {
    const fields = base64.split(';')

    const contentType = fields[0].split(':')[1]

    const data = fields[1].split(',')[1]

    return { contentType, data }
  }

  async downloadAttachment(url: string, filename: string) {
    const fileRef = ref(this.storage, url)

    try {
      const metadata = await getMetadata(fileRef)

      const blob = await getBlob(fileRef)

      const contentType = metadata.contentType || 'image/*'

      const element = document.createElement('a')

      element.href = URL.createObjectURL(blob)
      element.download = `${filename}.${contentType.split('/')[1]}`
      element.click()
    } catch (error) {
      Logger.error('Error downloading attachment', error)
      throw ErrorService.get(ErrorCodes.ERROR_DOWNLOADING_ATTACHMENT)
    }
  }

  async uploadAttachment(
    entity: 'rawMaterial' | 'commit' | 'geoCheckpoint',
    entityId: string,
    base64: string,
  ) {
    try {
      const { contentType, data } = this.parseFieldsBase64(base64)

      const attachmentPath = `${entity}/${entityId}/${uuidv4()}.${contentType.replace('image/', '')}`

      const fileRef = ref(this.storage, attachmentPath)

      await uploadString(fileRef, data, 'base64', {
        contentType,
      })

      const url = await this.getURLAttachment(fileRef)

      if (!url) {
        throw ErrorService.get(ErrorCodes.ERROR_UPLOADING_ATTACHMENT)
      }

      return url
    } catch (error) {
      Logger.error('Error uploading attachment', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPLOADING_ATTACHMENT)
    }
  }

  findItemOnResults<T extends { uid: string }>(
    results: Array<[QueryKey, T[] | undefined]>,
    uid: string,
  ) {
    for (const [, result] of results) {
      if (!result) continue

      const dataByID = result.find((item) => item.uid === uid)

      if (dataByID) {
        return dataByID
      }
    }

    return null
  }

  //Raw material
  parseToRawMaterialBase(rawMaterialFirebase: RawMaterialFirebaseType) {
    const {
      uid,
      name,
      description,
      measure,
      alertStock,
      value,
      deleted,
      attachments,
      updatedAt,
      createdAt,
    } = rawMaterialFirebase

    const rawMaterialBase: RawMaterialBaseType = {
      uid,
      name,
      description,
      measure,
      alertStock,
      value,
      deleted,
      attachments,
      updatedAt,
      createdAt,
    }

    return rawMaterialBase
  }

  async parseToRawMaterial(warehouseID: string, rawMaterialFirebase: RawMaterialFirebaseType) {
    const { uid, name, description, measure, alertStock, value, deleted, updatedAt, attachments } =
      rawMaterialFirebase

    //Get stock from collection Stock, the id record is `warehouseID-rawMaterialID`
    const { quantity } = await this.parseRefToEntity<StockBaseType>(
      doc(this.db, Collections.STOCKS.NAME, this.getStockId(warehouseID, uid)),
    )

    const warehouseData = await this.parseRefToEntity<WarehouseFirebaseType>(
      doc(this.db, Collections.WAREHOUSES.NAME, warehouseID),
    )

    const warehouseBase: WarehouseBaseType = {
      uid: warehouseData.uid,
      name: warehouseData.name,
      inHouse: warehouseData.inHouse,
      deleted: warehouseData.deleted,
      description: warehouseData.description,
      updatedAt: warehouseData.updatedAt,
      createdAt: warehouseData.createdAt,
    }

    return {
      uid,
      name,
      measure,
      value,
      deleted,
      updatedAt,
      alertStock,
      description,
      attachments,
      stock: quantity,
      warehouse: warehouseBase,
    } as RawMaterialWarehouseType
  }

  async parseMaterialToRef(materials: (RawMaterialWarehouseType | RawMaterialBaseType)[]) {
    const refs = []

    for (const material of materials) {
      const docRef = doc(this.db, Collections.RAW_MATERIALS.NAME, material.uid)
      refs.push(docRef)
    }

    return refs
  }

  async getRawMaterials(params: GetRawMaterialsType) {
    try {
      const collectionRef = collection(this.db, Collections.RAW_MATERIALS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.field && params.query) {
        if (['value', 'measure'].includes(params.field)) {
          queries.push(where(params.field, '==', params.query))
        }
      } else if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      // queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))
      queries.push(orderBy('name', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const rawMaterialsSnap = await getDocs(q)

      let rawMaterialsData = rawMaterialsSnap.docs.map((doc) =>
        doc.data(),
      ) as RawMaterialFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        rawMaterialsData = rawMaterialsData.filter((rawMaterial) => {
          const value = rawMaterial[params.field as keyof RawMaterialFirebaseType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const rawMaterialsBase: RawMaterialBaseType[] = []

      for (const rawMaterial of rawMaterialsData) {
        rawMaterialsBase.push(this.parseToRawMaterialBase(rawMaterial))
      }

      return rawMaterialsBase
    } catch (error) {
      Logger.error('Error getting raw materials', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async getRawMaterialById(params: GetRawMaterialWarehouseType) {
    try {
      const { warehouseID, rawMaterialID } = params

      const docRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterialID)

      const rmFirebaseData = await this.parseRefToEntity<RawMaterialFirebaseType>(docRef)

      const rawMaterialData = await this.parseToRawMaterial(warehouseID, rmFirebaseData)

      return rawMaterialData
    } catch (error) {
      Logger.error('Error getting raw material by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async getRawMaterialBaseById(id: string) {
    try {
      const docRef = doc(this.db, Collections.RAW_MATERIALS.NAME, id)

      const rawMaterialSnap = await this.parseRefToEntity<RawMaterialFirebaseType>(docRef)

      return this.parseToRawMaterialBase(rawMaterialSnap)
    } catch (error) {
      Logger.error('Error getting raw material by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RAW_MATERIALS)
    }
  }

  async createRawMaterial(params: CreateRawMaterialBaseType) {
    try {
      const { rawMateria } = params

      const rawMaterialBase = await runTransaction<RawMaterialBaseType>(
        this.db,
        async (transaction) => {
          const uid = uuidv4()
          const attachments: string[] = []

          for (const attachment of rawMateria.attachments) {
            if (attachment.startsWith('data:')) {
              const attachmentPath = await this.uploadAttachment('rawMaterial', uid, attachment)
              attachments.push(attachmentPath)
            } else {
              attachments.push(attachment)
            }
          }

          const currentTimestamp = moment().unix()

          const newRawMaterial: RawMaterialFirebaseType = {
            description: rawMateria.description,
            alertStock: rawMateria.alertStock,
            measure: rawMateria.measure,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
            value: rawMateria.value,
            name: rawMateria.name,
            deleted: false,
            attachments,
            uid,
          }

          transaction.set(
            doc(this.db, Collections.RAW_MATERIALS.NAME, newRawMaterial.uid),
            newRawMaterial,
          )

          return newRawMaterial as RawMaterialBaseType
        },
      )

      return rawMaterialBase
    } catch (error) {
      Logger.error('Error creating raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_RAW_MATERIAL)
    }
  }

  async updateRawMaterial(params: UpdateRawMaterialBaseType) {
    try {
      await runTransaction(this.db, async (transaction) => {
        const { rawMateria } = params

        const attachments: string[] = []

        if (rawMateria.attachments?.length) {
          const newAttachments: string[] = []

          for (const attachment of rawMateria.attachments) {
            if (attachment.startsWith('data:')) {
              const attachmentPath = await this.uploadAttachment(
                'rawMaterial',
                rawMateria.uid,
                attachment,
              )

              newAttachments.push(attachmentPath)
            } else {
              newAttachments.push(attachment)
            }
          }

          attachments.push(...newAttachments)
        }

        const rawMaterialFirebaseData: Partial<RawMaterialFirebaseType> = {
          description: rawMateria.description,
          alertStock: rawMateria.alertStock,
          deleted: rawMateria.deleted,
          measure: rawMateria.measure,
          value: rawMateria.value,
          name: rawMateria.name,
          attachments,
          updatedAt: moment().unix(),
        }

        transaction.update(
          doc(this.db, Collections.RAW_MATERIALS.NAME, rawMateria.uid),
          rawMaterialFirebaseData,
        )
      })
    } catch (error) {
      Logger.error('Error updating raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterial(params: DeleteRawMaterialType) {
    try {
      const { rawMaterialID } = params

      await runTransaction(this.db, async (transaction) => {
        const rawMaterialRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterialID)

        const rawMaterialSnap = await transaction.get(rawMaterialRef)

        const rawMaterialData = rawMaterialSnap.data() as RawMaterialFirebaseType

        await this.deleteAttachments(rawMaterialData.attachments)

        transaction.delete(rawMaterialRef)

        //check all stock that use this raw material
        const stockCollectionRef = collection(this.db, Collections.STOCKS.NAME)

        const stockQuery = query(stockCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        const stockSnap = await getDocs(stockQuery)

        for (const doc of stockSnap.docs) {
          transaction.delete(doc.ref)
        }

        //delete commits
        const commitCollectionRef = collection(this.db, Collections.COMMITS.NAME)

        const commitQuery = query(commitCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        const commitSnap = await getDocs(commitQuery)

        for (const doc of commitSnap.docs) {
          transaction.delete(doc.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterials(params: DeleteRawMaterialsType) {
    try {
      const { rawMaterialIDs } = params

      for (const rawMaterialID of rawMaterialIDs) {
        //check all stock that use this raw material
        const stockCollectionRef = collection(this.db, Collections.STOCKS.NAME)

        const stockQuery = query(stockCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        const stockSnap = await getDocs(stockQuery)

        for (const doc of stockSnap.docs) {
          await deleteDoc(doc.ref)
        }

        const rawMaterialRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterialID)

        const rawMaterialSnap = await getDoc(rawMaterialRef)

        const rawMaterialData = rawMaterialSnap.data() as RawMaterialFirebaseType

        await this.deleteAttachments(rawMaterialData.attachments)

        await deleteDoc(rawMaterialRef)

        //delete commits
        const commitCollectionRef = collection(this.db, Collections.COMMITS.NAME)

        const commitQuery = query(commitCollectionRef, where('rawMaterialID', '==', rawMaterialID))

        const commitSnap = await getDocs(commitQuery)

        for (const doc of commitSnap.docs) {
          await deleteDoc(doc.ref)
        }
      }
    } catch (error) {
      Logger.error('Error deleting raw materials', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS)
    }
  }

  //Warehouse
  parseToWarehouseBase(warehouseFirebase: WarehouseFirebaseType) {
    const { uid, name, description, deleted, updatedAt } = warehouseFirebase

    return {
      uid,
      name,
      deleted,
      updatedAt,
      description,
    } as WarehouseBaseType
  }

  async parseToWarehouse(warehouseFirebase: WarehouseFirebaseType) {
    const { uid, name, description, deleted, updatedAt } = warehouseFirebase

    const rawMaterials: RawMaterialBaseType[] = []

    for (const rawMaterialRef of warehouseFirebase.rawMaterials) {
      const rawMaterialData = await this.parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

      rawMaterials.push(this.parseToRawMaterialBase(rawMaterialData))
    }

    return {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      rawMaterials,
    } as WarehouseType
  }

  async getWarehouses(params: GetWarehousesType) {
    try {
      const collectionRef = collection(this.db, Collections.WAREHOUSES.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'asc'))

      const q = query(collectionRef, ...queries)

      const warehousesSnap = await getDocs(q)

      const warehousesData = warehousesSnap.docs.map((doc) => doc.data()) as WarehouseFirebaseType[]

      const warehouseBase: WarehouseBaseType[] = []

      for (const warehouse of warehousesData) {
        warehouseBase.push({
          uid: warehouse.uid,
          name: warehouse.name,
          inHouse: warehouse.inHouse,
          deleted: warehouse.deleted,
          description: warehouse.description,
          updatedAt: warehouse.updatedAt,
          createdAt: warehouse.createdAt,
        })
      }

      return warehouseBase
    } catch (error) {
      Logger.error('Error getting warehouses', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_WAREHOUSES)
    }
  }

  async getWarehouseById(id: string) {
    try {
      const docRef = doc(this.db, Collections.WAREHOUSES.NAME, id)

      const warehouseSnap = await this.parseRefToEntity<WarehouseFirebaseType>(docRef)

      const rawMaterials = await this.getMaterialsByWarehouse({ warehouseID: id })

      const warehouseData: WarehouseType = {
        uid: warehouseSnap.uid,
        name: warehouseSnap.name,
        inHouse: warehouseSnap.inHouse,
        description: warehouseSnap.description,
        updatedAt: warehouseSnap.updatedAt,
        createdAt: warehouseSnap.createdAt,
        deleted: warehouseSnap.deleted,
        rawMaterials: rawMaterials,
      }

      return warehouseData
    } catch (error) {
      Logger.error('Error getting warehouse by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_WAREHOUSES)
    }
  }

  async getMaterialsByWarehouse(params: GetRawMaterialsWarehouseType) {
    try {
      const { warehouseID } = params

      const warehouseRef = doc(this.db, Collections.WAREHOUSES.NAME, warehouseID)

      const warehouseData = await this.parseRefToEntity<WarehouseFirebaseType>(warehouseRef)

      const rawMaterials: RawMaterialWarehouseType[] = []

      for (const rawMaterialRef of warehouseData.rawMaterials) {
        const rawMaterialData = await this.parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

        rawMaterials.push(await this.parseToRawMaterial(warehouseID, rawMaterialData))
      }

      return rawMaterials
    } catch (error) {
      Logger.error('Error getting materials by warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_MATERIALS_BY_WAREHOUSE)
    }
  }

  async createWarehouse(data: CreateWarehouseType) {
    try {
      const warehouse = await runTransaction<WarehouseType>(this.db, async (transaction) => {
        const uid = uuidv4()

        const rawMaterials: DocumentReference[] = []

        for (const rawMaterial of data.rawMaterials) {
          const docRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)
          rawMaterials.push(docRef)

          const stockRef = doc(
            this.db,
            Collections.STOCKS.NAME,
            this.getStockId(uid, rawMaterial.uid),
          )

          const currentTimestamp = moment().unix()

          const stockData: StockBaseType = {
            warehouseID: uid,
            quantity: rawMaterial.stock,
            rawMaterialID: rawMaterial.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          transaction.set(stockRef, stockData)
        }

        const currentTimestamp = moment().unix()

        const newWarehouse: WarehouseFirebaseType = {
          ...data,
          uid,
          rawMaterials,
          deleted: false,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        transaction.set(doc(this.db, Collections.WAREHOUSES.NAME, newWarehouse.uid), newWarehouse)

        return {
          ...data,
          uid: newWarehouse.uid,
          deleted: newWarehouse.deleted,
          updatedAt: newWarehouse.updatedAt,
        } as WarehouseType
      })

      return warehouse
    } catch (error) {
      Logger.error('Error creating warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_WAREHOUSE)
    }
  }

  async updateWarehouse(data: UpdateWarehouseType) {
    try {
      const rawMaterials: DocumentReference[] = []

      if (data.rawMaterials) {
        for (const rawMaterial of data.rawMaterials) {
          const docRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)
          rawMaterials.push(docRef)
        }
      }

      const warehouseFirebaseData: Partial<WarehouseFirebaseType> = {
        name: data.name,
        deleted: data.deleted,
        updatedAt: moment().unix(),
        description: data.description,
        rawMaterials,
      }

      const docRef = doc(this.db, Collections.WAREHOUSES.NAME, data.uid)

      await updateDoc(docRef, warehouseFirebaseData)
    } catch (error) {
      Logger.error('Error updating warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_WAREHOUSE)
    }
  }

  async deleteWarehouse(id: string) {
    try {
      const docRef = doc(this.db, Collections.WAREHOUSES.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSE)
    }
  }

  async deleteWarehouses(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      ids.forEach((id) => {
        const docRef = doc(this.db, Collections.WAREHOUSES.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting warehouses', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_WAREHOUSES)
    }
  }

  async moveRawMaterials(params: MoveRawMaterialsType) {
    try {
      const user = await this.getUser()

      if (!user) {
        throw ErrorService.get(ErrorCodes.ERROR_SESSION_EXPIRED)
      }

      const { rawMaterials, warehouseFromID, warehouseToID } = params

      const rmCommitRefs: CommitFirebaseType['rawMaterials'] = []

      await runTransaction(this.db, async (transaction) => {
        const warehouseFromRef = doc(this.db, Collections.WAREHOUSES.NAME, warehouseFromID)
        const warehouseToRef = doc(this.db, Collections.WAREHOUSES.NAME, warehouseToID)

        const warehouseFromSnap = await getDoc(warehouseFromRef)
        const warehouseToSnap = await getDoc(warehouseToRef)

        //let changeFromWarehouse = false
        const warehouseFromData = warehouseFromSnap.data() as WarehouseFirebaseType
        let changeToWarehouse = false
        const warehouseToData = warehouseToSnap.data() as WarehouseFirebaseType

        const currentTimestamp = moment().unix()

        for (const { rawMaterialID, quantity } of rawMaterials) {
          const stockFromRef = doc(
            this.db,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseFromID, rawMaterialID),
          )

          const stockFromSnap = await transaction.get(stockFromRef)

          const stockFromData = stockFromSnap.data() as StockBaseType

          const stockToRef = doc(
            this.db,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseToID, rawMaterialID),
          )

          const stockToSnap = await transaction.get(stockToRef)

          const stockToData = stockToSnap.data() as StockBaseType

          if (stockFromData.quantity < quantity) {
            throw ErrorService.get(ErrorCodes.ERROR_NOT_ENOUGH_STOCK)
          }

          transaction.update(stockFromRef, {
            quantity: stockFromData.quantity - quantity,
            updatedAt: moment().unix(),
          })

          if (stockToSnap.exists()) {
            transaction.update(stockToRef, {
              quantity: stockToData.quantity + quantity,
              updatedAt: moment().unix(),
            })
          } else {
            transaction.set(stockToRef, {
              warehouseID: warehouseToID,
              rawMaterialID,
              quantity,
              createdAt: moment().unix(),
              updatedAt: moment().unix(),
            })

            changeToWarehouse = true
            warehouseToData.rawMaterials.push(
              doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterialID),
            )
          }

          rmCommitRefs.push({
            ref: doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterialID),
            quantity,
          })
        }

        const newTargetCommit: CommitFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: this.parseEntitytoRef(user),
          rawMaterials: rmCommitRefs,
          message: `[Sistema] Movimiento de materiales desde ${warehouseFromData.name}`,
          warehouse: doc(this.db, Collections.WAREHOUSES.NAME, warehouseToID),
        }

        transaction.set(
          doc(this.db, Collections.COMMITS.NAME, newTargetCommit.uid),
          newTargetCommit,
        )

        const newSourceCommit: CommitFirebaseType = {
          uid: uuidv4(),
          action: 'move',
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          user: this.parseEntitytoRef(user),
          rawMaterials: rmCommitRefs,
          message: `[Sistema] Movimiento de materiales hacia ${warehouseToData.name}`,
          warehouse: doc(this.db, Collections.WAREHOUSES.NAME, warehouseFromID),
        }

        transaction.set(
          doc(this.db, Collections.COMMITS.NAME, newSourceCommit.uid),
          newSourceCommit,
        )

        if (changeToWarehouse) {
          transaction.update(warehouseToRef, {
            rawMaterials: warehouseToData.rawMaterials,
            updatedAt: moment().unix(),
          })
        }
      })
    } catch (error) {
      Logger.error('Error moving raw material', error)
      throw ErrorService.get(ErrorCodes.ERROR_MOVING_RAW_MATERIAL)
    }
  }

  async deleteRawMaterialsFromWarehouse(
    params: DeleteRawMaterialsFromWarehouseType,
  ): Promise<void> {
    try {
      const { rawMaterialIDs, warehouseID } = params

      await runTransaction(this.db, async (transaction) => {
        const warehouseRef = doc(this.db, Collections.WAREHOUSES.NAME, warehouseID)

        const warehouseSnap = await transaction.get(warehouseRef)

        const warehouseData = warehouseSnap.data() as WarehouseFirebaseType

        for (const rawMaterialID of rawMaterialIDs) {
          const stockRef = doc(
            this.db,
            Collections.STOCKS.NAME,
            this.getStockId(warehouseID, rawMaterialID),
          )

          transaction.delete(stockRef)

          const index = warehouseData.rawMaterials.findIndex((rm) => rm.id === rawMaterialID)

          if (index !== -1) {
            warehouseData.rawMaterials.splice(index, 1)
          }
        }

        transaction.update(warehouseRef, {
          rawMaterials: warehouseData.rawMaterials,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting raw materials from warehouse', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS_FROM_WAREHOUSE)
    }
  }

  //Commits
  getStockId(entityID: string, rawMaterialID: string) {
    return `${entityID}-${rawMaterialID}`
  }

  //create another getCommitsAll for fetch to all rawMaterials
  async getCommits(params: GetCommitsType) {
    try {
      const collectionRef = collection(this.db, Collections.COMMITS.NAME)

      const queries: QueryConstraint[] = [
        where('warehouse', '==', doc(this.db, Collections.WAREHOUSES.NAME, params.warehouseID)),
      ]

      if (params.field && params.query) {
        if (params.startDate && params.endDate) {
          queries.push(where(params.field, '>=', params.startDate))
          queries.push(where(params.field, '<=', params.endDate))
        }
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const commitsSnap = await getDocs(q)

      const commitsFirebaseData = commitsSnap.docs.map((doc) => doc.data()) as CommitFirebaseType[]

      const commitData: CommitBaseType[] = []

      for (let idx = 0; idx < commitsFirebaseData.length; idx++) {
        const commit = commitsFirebaseData[idx]

        const user = await this.parseRefToEntity<UserType>(commit.user as DocumentReference)

        const rawMaterials: CommitBaseType['rawMaterials'] = []

        for (const rawMaterial of commit.rawMaterials) {
          const rawMaterialFirebaseData = await this.parseRefToEntity<RawMaterialFirebaseType>(
            rawMaterial.ref,
          )

          rawMaterials.push({
            ...this.parseToRawMaterialBase(rawMaterialFirebaseData),
            quantity: rawMaterial.quantity,
          })
        }

        commitData.push({
          user,
          uid: commit.uid,
          action: commit.action,
          message: commit.message,
          updatedAt: commit.updatedAt,
          rawMaterials,
          createdAt: commit.createdAt,
          attachment: commit.attachment,
        })
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async getCommitById(id: string) {
    try {
      const docRef = doc(this.db, Collections.COMMITS.NAME, id)

      const commitSnap = await getDoc(docRef)

      const commitFirebaseData = commitSnap.data() as CommitFirebaseType

      const user = await this.parseRefToEntity<UserType>(commitFirebaseData.user)

      const rawMaterials: CommitBaseType['rawMaterials'] = []

      for (const rawMaterial of commitFirebaseData.rawMaterials) {
        const rawMaterialFirebaseData = await this.parseRefToEntity<RawMaterialFirebaseType>(
          rawMaterial.ref,
        )

        rawMaterials.push({
          ...this.parseToRawMaterialBase(rawMaterialFirebaseData),
          quantity: rawMaterial.quantity,
        })
      }

      const warehouseFirebaseData = await this.parseRefToEntity<WarehouseFirebaseType>(
        commitFirebaseData.warehouse,
      )

      const warehouseBaseData = this.parseToWarehouseBase(warehouseFirebaseData)

      const commitData: CommitType = {
        uid: commitFirebaseData.uid,
        action: commitFirebaseData.action,
        message: commitFirebaseData.message,
        updatedAt: commitFirebaseData.updatedAt,
        createdAt: commitFirebaseData.createdAt,
        attachment: commitFirebaseData.attachment,
        warehouse: warehouseBaseData,
        rawMaterials,
        user,
      }

      return commitData
    } catch (error) {
      Logger.error('Error getting commit by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_COMMITS)
    }
  }

  async createCommit(params: CreateCommitType) {
    try {
      const { warehouseID, commit } = params

      const commitData = await runTransaction<CommitBaseType>(this.db, async (transaction) => {
        const currentUser = await this.getUser()

        if (!currentUser) {
          throw ErrorService.get(ErrorCodes.ERROR_GETTING_USER)
        }

        const userRef = this.parseEntitytoRef<UserType>(currentUser)

        const userData = await this.parseRefToEntity<UserType>(userRef)

        const warehouseRef = doc(this.db, Collections.WAREHOUSES.NAME, warehouseID)

        if (commit.attachment) {
          commit.attachment = await this.uploadAttachment('commit', warehouseID, commit.attachment)
        }

        const rawMaterialFirebase: CommitFirebaseType['rawMaterials'] = []

        for (const rawMaterial of commit.rawMaterials) {
          const rawMaterialRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)

          rawMaterialFirebase.push({
            ref: rawMaterialRef,
            quantity: rawMaterial.quantity,
          })
        }

        const currentTimestamp = moment().unix()

        const commitFirebase: CommitFirebaseType = {
          uid: uuidv4(),
          action: commit.action,
          message: commit.message,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          attachment: commit.attachment,
          rawMaterials: rawMaterialFirebase,
          warehouse: warehouseRef,
          user: userRef,
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number; uid: string }[] = []

        for (const rawMaterial of commit.rawMaterials) {
          const { uid: rawMaterialID, quantity } = rawMaterial

          const stockID = this.getStockId(warehouseID, rawMaterialID)

          const stockRef = doc(this.db, Collections.STOCKS.NAME, stockID)

          const stockSnap = await transaction.get(stockRef)

          stockSnaps.push({ snap: stockSnap, quantity, uid: rawMaterialID })
        }

        //Update stock quantity
        for (const item of stockSnaps) {
          const { snap, quantity, uid } = item

          if (commit.action === 'consume') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              transaction.update(snap.ref, { quantity: stockData.quantity - quantity })
            }
          } else if (commit.action === 'refill') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
            } else {
              const stockData: StockBaseType = {
                quantity,
                warehouseID,
                rawMaterialID: uid,
                updatedAt: currentTimestamp,
                createdAt: currentTimestamp,
              }

              transaction.set(snap.ref, stockData)
            }
          }
        }

        transaction.set(doc(this.db, Collections.COMMITS.NAME, commitFirebase.uid), commitFirebase)

        const commitData: CommitBaseType = {
          uid: commitFirebase.uid,
          action: commitFirebase.action,
          message: commitFirebase.message,
          updatedAt: commitFirebase.updatedAt,
          createdAt: commitFirebase.createdAt,
          attachment: commitFirebase.attachment,
          rawMaterials: commit.rawMaterials,
          user: userData,
        }

        return commitData
      })

      return commitData
    } catch (error) {
      Logger.error('Error creating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_COMMIT)
    }
  }

  async updateCommit(params: UpdateCommitType) {
    try {
      const { previous, current, warehouseID } = params

      await runTransaction(this.db, async (transaction) => {
        const newData: Partial<CommitFirebaseType> = {
          rawMaterials: [],
        }

        if (current.message && current.message !== previous.message) {
          newData.message = current.message
        }
        if (current.action && current.action !== previous.action) {
          newData.action = current.action
        }
        if (current.attachment && current.attachment !== previous.attachment) {
          newData.attachment = await this.uploadAttachment(
            'commit',
            warehouseID,
            current.attachment,
          )
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number; previousQty: number }[] = []

        for (const currentData of current.rawMaterials) {
          const previousData = previous.rawMaterials.find((rm) => rm.uid === currentData.uid)

          const previousQty = previousData?.quantity || 0

          if (currentData.quantity && currentData.quantity !== previousQty) {
            const stockID = this.getStockId(warehouseID, currentData.uid)

            newData.rawMaterials?.push({
              ref: doc(this.db, Collections.RAW_MATERIALS.NAME, currentData.uid),
              quantity: currentData.quantity,
            })

            if (current.action === 'consume') {
              const stockRef = doc(this.db, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            } else if (current.action === 'refill') {
              const stockRef = doc(this.db, Collections.STOCKS.NAME, stockID)

              const stockSnap = await transaction.get(stockRef)

              stockSnaps.push({ snap: stockSnap, quantity: currentData.quantity, previousQty })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity, previousQty } = item

          if (current.action === 'consume') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              const tempStock = stockData.quantity + previousQty

              transaction.update(snap.ref, { quantity: tempStock - quantity })
            }
          } else if (current.action === 'refill') {
            if (snap.exists()) {
              const stockData = snap.data() as StockBaseType

              const tempStock = stockData.quantity - previousQty

              transaction.update(snap.ref, { quantity: tempStock + quantity })
            }
          }
        }

        if (Object.keys(newData).length === 0) {
          throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
        }

        const docRef = doc(this.db, Collections.COMMITS.NAME, previous.uid)

        transaction.update(docRef, newData)
      })
    } catch (error) {
      Logger.error('Error updating commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_COMMIT)
    }
  }

  async deleteCommit(id: string) {
    try {
      const docRef = doc(this.db, Collections.COMMITS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting commit', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMIT)
    }
  }

  async deleteCommits(ids: string[]) {
    try {
      await runTransaction(this.db, async (transaction) => {
        const commitSnaps: DocumentSnapshot[] = []

        for (const id of ids) {
          const docRef = doc(this.db, Collections.COMMITS.NAME, id)

          const commitSnap = await transaction.get(docRef)

          if (commitSnap.exists()) {
            commitSnaps.push(commitSnap)
          }
        }

        const stockSnaps: { snap: DocumentSnapshot; quantity: number }[] = []

        for (const commitSnap of commitSnaps) {
          const commitData = commitSnap.data() as CommitFirebaseType

          for (const rawMaterial of commitData.rawMaterials) {
            const stockID = this.getStockId(commitData.warehouse.id, rawMaterial.ref.id)

            const stockRef = doc(this.db, Collections.STOCKS.NAME, stockID)

            const stockSnap = await transaction.get(stockRef)

            if (stockSnap.exists()) {
              stockSnaps.push({ snap: stockSnap, quantity: rawMaterial.quantity })
            }
          }
        }

        for (const item of stockSnaps) {
          const { snap, quantity } = item

          const stockData = snap.data() as StockBaseType

          transaction.update(snap.ref, { quantity: stockData.quantity + quantity })
        }

        for (const commitSnap of commitSnaps) {
          transaction.delete(commitSnap.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting commits', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_COMMITS)
    }
  }

  //Receipts
  parseToReceiptBase(receiptFirebase: ReceiptsFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt } = receiptFirebase

    const receiptBase: ReceiptBaseType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
    }

    return receiptBase
  }

  async parseToReceipt(receiptFirebase: ReceiptsFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt } = receiptFirebase

    const rawMaterials: RawMaterialReceiptType[] = []

    for (const rawMaterialRef of receiptFirebase.rawMaterials) {
      const rawMaterialData = await this.parseRefToEntity<RawMaterialFirebaseType>(rawMaterialRef)

      const qtyReceipt = doc(
        this.db,
        Collections.QTY_RECEIPTS.NAME,
        this.getStockId(uid, rawMaterialData.uid),
      )
      const qtyReceiptData = await this.parseRefToEntity<QtyReceiptBaseType>(qtyReceipt)

      const rawMaterialReceipt: RawMaterialReceiptType = {
        uid: rawMaterialData.uid,
        name: rawMaterialData.name,
        measure: rawMaterialData.measure,
        value: rawMaterialData.value,
        deleted: rawMaterialData.deleted,
        updatedAt: rawMaterialData.updatedAt,
        createdAt: rawMaterialData.createdAt,
        alertStock: rawMaterialData.alertStock,
        description: rawMaterialData.description,
        attachments: rawMaterialData.attachments,
        quantity: qtyReceiptData.quantity,
      }
      rawMaterials.push(rawMaterialReceipt)
    }

    const receiptData: ReceiptType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      rawMaterials,
    }

    return receiptData
  }

  async getReceipts(params: GetReceiptsType) {
    try {
      const collectionRef = collection(this.db, Collections.RECEIPTS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const receiptsSnap = await getDocs(q)

      let receiptsData = receiptsSnap.docs.map((doc) => doc.data()) as ReceiptsFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        receiptsData = receiptsData.filter((receipt) => {
          const value = receipt[params.field as keyof ReceiptType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const receiptsBase: ReceiptBaseType[] = []

      for (const receipt of receiptsData) {
        receiptsBase.push(this.parseToReceiptBase(receipt))
      }

      return receiptsBase
    } catch (error) {
      Logger.error('Error getting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async getReceiptsExtended(params: GetReceiptsType) {
    try {
      const collectionRef = collection(this.db, Collections.RECEIPTS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const receiptsSnap = await getDocs(q)

      let receiptsData = receiptsSnap.docs.map((doc) => doc.data()) as ReceiptsFirebaseType[]

      if (params.field && params.query && ['name', 'description', 'uid'].includes(params.field)) {
        receiptsData = receiptsData.filter((receipt) => {
          const value = receipt[params.field as keyof ReceiptType] as string

          return value.toLowerCase().includes(params.query?.toLowerCase() || '')
        })
      }

      const receipts: ReceiptType[] = []

      for (const receipt of receiptsData) {
        const receiptData = await this.parseToReceipt(receipt)

        receipts.push(receiptData)
      }

      return receipts
    } catch (error) {
      Logger.error('Error getting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async getReceiptById(id: string) {
    try {
      //try cache
      const dataByID = queryClient.getQueryData<ReceiptType>([QueryKeys.GET_RECEIPT_KEY, id])

      if (dataByID) {
        return dataByID
      }

      const docRef = doc(this.db, Collections.RECEIPTS.NAME, id)

      const receiptSnap = await getDoc(docRef)

      const receiptFirebaseData = receiptSnap.data() as ReceiptsFirebaseType

      const receiptData = await this.parseToReceipt(receiptFirebaseData)

      return receiptData
    } catch (error) {
      Logger.error('Error getting receipt by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_RECEIPTS)
    }
  }

  async createReceipt(data: CreateReceiptType) {
    try {
      const currentTimestamp = moment().unix()

      const newReceipt: ReceiptsFirebaseType = {
        ...data,
        deleted: false,
        rawMaterials: [],
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
      }

      for (const rawMaterial of data.rawMaterials) {
        const rawMaterialRef = doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterial.uid)

        const qtyReceipt: QtyReceiptBaseType = {
          receiptID: newReceipt.uid,
          rawMaterialID: rawMaterial.uid,
          quantity: rawMaterial.quantity,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        const qtyReceiptRef = doc(
          this.db,
          Collections.QTY_RECEIPTS.NAME,
          this.getStockId(newReceipt.uid, rawMaterial.uid),
        )

        await setDoc(qtyReceiptRef, qtyReceipt)

        newReceipt.rawMaterials.push(rawMaterialRef)
      }

      await setDoc(doc(this.db, Collections.RECEIPTS.NAME, newReceipt.uid), newReceipt)

      const receiptData: ReceiptType = {
        uid: newReceipt.uid,
        name: newReceipt.name,
        description: newReceipt.description,
        deleted: newReceipt.deleted,
        updatedAt: newReceipt.updatedAt,
        createdAt: newReceipt.createdAt,
        rawMaterials: data.rawMaterials,
      }

      return receiptData
    } catch (error) {
      Logger.error('Error creating receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_RECEIPT)
    }
  }

  async updateReceipt(params: UpdateReceiptType) {
    try {
      const { current, previous } = params

      const docRef = doc(this.db, Collections.RECEIPTS.NAME, previous.uid)

      const updateReceipt: Partial<ReceiptsFirebaseType> = {
        rawMaterials: [],
      }

      if (current.name && current.name !== previous.name) {
        updateReceipt.name = current.name
      }
      if (current.description && current.description !== previous.description) {
        updateReceipt.description = current.description
      }
      if (current.rawMaterials) {
        for (const rawMaterial of current.rawMaterials) {
          const currentTimestamp = moment().unix()

          const qtyReceipt: QtyReceiptBaseType = {
            rawMaterialID: rawMaterial.uid,
            quantity: rawMaterial.quantity,
            receiptID: previous.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          const qtyReceiptRef = doc(
            this.db,
            Collections.QTY_RECEIPTS.NAME,
            this.getStockId(previous.uid, rawMaterial.uid),
          )

          await setDoc(qtyReceiptRef, qtyReceipt)

          if (updateReceipt.rawMaterials) {
            updateReceipt.rawMaterials.push(
              doc(this.db, Collections.RAW_MATERIALS.NAME, rawMaterial.uid),
            )
          }
        }
      }

      if (Object.keys(updateReceipt).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      await updateDoc(docRef, updateReceipt)
    } catch (error) {
      Logger.error('Error updating receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_RECEIPT)
    }
  }

  async deleteReceipt(id: string) {
    try {
      const docRef = doc(this.db, Collections.RECEIPTS.NAME, id)

      await deleteDoc(docRef)

      const qtyReceiptCollectionRef = collection(this.db, Collections.QTY_RECEIPTS.NAME)

      const qtyReceiptQuery = query(qtyReceiptCollectionRef, where('receiptID', '==', id))

      const qtyReceiptSnap = await getDocs(qtyReceiptQuery)

      for (const doc of qtyReceiptSnap.docs) {
        await deleteDoc(doc.ref)
      }
    } catch (error) {
      Logger.error('Error deleting receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPT)
    }
  }

  async deleteReceipts(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      for (const id of ids) {
        const docRef = doc(this.db, Collections.RECEIPTS.NAME, id)

        batch.delete(docRef)

        const qtyReceiptCollectionRef = collection(this.db, Collections.QTY_RECEIPTS.NAME)

        const qtyReceiptQuery = query(qtyReceiptCollectionRef, where('receiptID', '==', id))

        const qtyReceiptSnap = await getDocs(qtyReceiptQuery)

        for (const doc of qtyReceiptSnap.docs) {
          batch.delete(doc.ref)
        }
      }

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting receipts', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPTS)
    }
  }

  async deleteRawMaterialsFromReceipt(params: DeleteRawMaterialsFromReceiptType) {
    try {
      const { receiptID, rawMaterialIDs } = params

      await runTransaction(this.db, async (transaction) => {
        const receiptRef = doc(this.db, Collections.RECEIPTS.NAME, receiptID)

        const receiptSnap = await transaction.get(receiptRef)

        const receiptData = receiptSnap.data() as ReceiptsFirebaseType

        for (const rawMaterialID of rawMaterialIDs) {
          const qtyReceiptRef = doc(
            this.db,
            Collections.QTY_RECEIPTS.NAME,
            this.getStockId(receiptID, rawMaterialID),
          )

          transaction.delete(qtyReceiptRef)

          const index = receiptData.rawMaterials.findIndex((rm) => rm.id === rawMaterialID)

          if (index !== -1) {
            receiptData.rawMaterials.splice(index, 1)
          }
        }

        transaction.update(receiptRef, {
          rawMaterials: receiptData.rawMaterials,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting raw materials from receipt', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RAW_MATERIALS_FROM_RECEIPT)
    }
  }

  //Blocks
  parseToBlockBase(blockFirebase: BlockFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt } = blockFirebase

    const blockBase: BlockBaseType = {
      uid,
      name,
      deleted,
      updatedAt,
      createdAt,
      description,
    }

    return blockBase
  }

  async parseToBlock(blockFirebase: BlockFirebaseType) {
    const { uid, name, description, deleted, updatedAt, createdAt } = blockFirebase

    const receipts: ReceiptBlockType[] = []

    for (const receiptRef of blockFirebase.receipts) {
      const receiptFirebaseData = await this.parseRefToEntity<ReceiptsFirebaseType>(receiptRef)

      const receiptData = await this.parseToReceipt(receiptFirebaseData)

      const qtyBlock = doc(
        this.db,
        Collections.QTY_BLOCKS.NAME,
        this.getStockId(uid, receiptFirebaseData.uid),
      )

      const qtyBlockData = await this.parseRefToEntity<QtyBlockBaseType>(qtyBlock)

      const receiptBlock: ReceiptBlockType = {
        uid: receiptData.uid,
        name: receiptData.name,
        description: receiptData.description,
        deleted: receiptData.deleted,
        updatedAt: receiptData.updatedAt,
        createdAt: receiptData.createdAt,
        rawMaterials: receiptData.rawMaterials,
        quantity: qtyBlockData.quantity,
      }

      receipts.push(receiptBlock)
    }

    const blockData: BlockType = {
      uid,
      name,
      description,
      deleted,
      updatedAt,
      createdAt,
      receipts,
    }

    return blockData
  }

  async getBlocks(params: GetBlocksType) {
    try {
      const collectionRef = collection(this.db, Collections.BLOCKS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const blocksSnap = await getDocs(q)

      const blocksData = blocksSnap.docs.map((doc) => doc.data()) as BlockFirebaseType[]

      const blocksBase: BlockBaseType[] = []

      for (const block of blocksData) {
        blocksBase.push(this.parseToBlockBase(block))
      }

      return blocksBase
    } catch (error) {
      Logger.error('Error getting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async getBlocksExtended(params: GetBlocksType) {
    try {
      const collectionRef = collection(this.db, Collections.BLOCKS.NAME)

      const queries: QueryConstraint[] = [where('deleted', '==', false)]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const blocksSnap = await getDocs(q)

      const blocksData = blocksSnap.docs.map((doc) => doc.data()) as BlockFirebaseType[]

      const blocks: BlockType[] = []

      for (const block of blocksData) {
        const blockData = await this.parseToBlock(block)

        blocks.push(blockData)
      }

      return blocks
    } catch (error) {
      Logger.error('Error getting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async getBlockById(id: string) {
    try {
      const docRef = doc(this.db, Collections.BLOCKS.NAME, id)

      const blockSnap = await getDoc(docRef)

      const blockFirebaseData = blockSnap.data() as BlockFirebaseType

      const blockData = await this.parseToBlock(blockFirebaseData)

      return blockData
    } catch (error) {
      Logger.error('Error getting block by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_BLOCKS)
    }
  }

  async createBlock(data: CreateBlockType) {
    try {
      const currentTimestamp = moment().unix()
      const newBlock: BlockFirebaseType = {
        ...data,
        deleted: false,
        receipts: [],
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
      }

      for (const receipt of data.receipts) {
        const receiptRef = doc(this.db, Collections.RECEIPTS.NAME, receipt.uid)

        const currentTimestamp = moment().unix()

        const qtyBlock: QtyBlockBaseType = {
          blockID: newBlock.uid,
          receiptID: receipt.uid,
          quantity: receipt.quantity,
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
        }

        const qtyBlockRef = doc(
          this.db,
          Collections.QTY_BLOCKS.NAME,
          this.getStockId(newBlock.uid, receipt.uid),
        )

        await setDoc(qtyBlockRef, qtyBlock)

        newBlock.receipts.push(receiptRef)
      }

      await setDoc(doc(this.db, Collections.BLOCKS.NAME, newBlock.uid), newBlock)

      const blockData: BlockType = {
        uid: newBlock.uid,
        name: newBlock.name,
        description: newBlock.description,
        deleted: newBlock.deleted,
        updatedAt: newBlock.updatedAt,
        createdAt: newBlock.createdAt,
        receipts: data.receipts,
      }

      return blockData
    } catch (error) {
      Logger.error('Error creating block', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_BLOCK)
    }
  }

  async updateBlock(params: UpdateBlockType) {
    try {
      const { current, previous } = params

      const docRef = doc(this.db, Collections.BLOCKS.NAME, previous.uid)

      const updateBlock: Partial<BlockFirebaseType> = {
        receipts: [],
      }

      if (current.name && current.name !== previous.name) {
        updateBlock.name = current.name
      }
      if (current.description && current.description !== previous.description) {
        updateBlock.description = current.description
      }
      if (current.receipts) {
        for (const receipt of current.receipts) {
          const currentTimestamp = moment().unix()

          const qtyBlock: QtyBlockBaseType = {
            blockID: previous.uid,
            quantity: receipt.quantity,
            receiptID: receipt.uid,
            updatedAt: currentTimestamp,
            createdAt: currentTimestamp,
          }

          const qtyBlockRef = doc(
            this.db,
            Collections.QTY_BLOCKS.NAME,
            this.getStockId(previous.uid, receipt.uid),
          )

          await setDoc(qtyBlockRef, qtyBlock)

          if (updateBlock.receipts) {
            updateBlock.receipts.push(doc(this.db, Collections.RECEIPTS.NAME, receipt.uid))
          }
        }
      }

      if (Object.keys(updateBlock).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      await updateDoc(docRef, updateBlock)
    } catch (error) {
      Logger.error('Error updating block', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_BLOCK)
    }
  }

  async deleteBlock(id: string) {
    try {
      const docRef = doc(this.db, Collections.BLOCKS.NAME, id)

      await deleteDoc(docRef)

      const qtyBlockCollectionRef = collection(this.db, Collections.QTY_BLOCKS.NAME)

      const qtyBlockQuery = query(qtyBlockCollectionRef, where('blockID', '==', id))

      const qtyBlockSnap = await getDocs(qtyBlockQuery)

      for (const doc of qtyBlockSnap.docs) {
        await deleteDoc(doc.ref)
      }
    } catch (error) {
      Logger.error('Error deleting block', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_BLOCK)
    }
  }

  async deleteBlocks(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      for (const id of ids) {
        const docRef = doc(this.db, Collections.BLOCKS.NAME, id)

        batch.delete(docRef)

        const qtyBlockCollectionRef = collection(this.db, Collections.QTY_BLOCKS.NAME)

        const qtyBlockQuery = query(qtyBlockCollectionRef, where('blockID', '==', id))

        const qtyBlockSnap = await getDocs(qtyBlockQuery)

        for (const doc of qtyBlockSnap.docs) {
          batch.delete(doc.ref)
        }
      }

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting blocks', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_BLOCKS)
    }
  }

  async deleteReceiptsFromBlock(params: DeleteReceiptsBlockType) {
    try {
      const { blockID, receiptIDs } = params

      await runTransaction(this.db, async (transaction) => {
        const blockRef = doc(this.db, Collections.BLOCKS.NAME, blockID)

        const blockSnap = await transaction.get(blockRef)

        const blockData = blockSnap.data() as BlockFirebaseType

        for (const receiptID of receiptIDs) {
          const qtyBlockRef = doc(
            this.db,
            Collections.QTY_BLOCKS.NAME,
            this.getStockId(blockID, receiptID),
          )

          transaction.delete(qtyBlockRef)

          const index = blockData.receipts.findIndex((rm) => rm.id === receiptID)

          if (index !== -1) {
            blockData.receipts.splice(index, 1)
          }
        }

        transaction.update(blockRef, {
          receipts: blockData.receipts,
          updatedAt: moment().unix(),
        })
      })
    } catch (error) {
      Logger.error('Error deleting receipts from block', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_RECEIPTS_FROM_BLOCK)
    }
  }

  //Clients
  paseToEventBase(eventFirebase: EventFirebaseType) {
    const { uid, attachments, date, description, name, amount, deleted, createdAt, updatedAt } =
      eventFirebase

    const eventBase: EventBaseType = {
      uid,
      description,
      name,
      amount,
      attachments,
      date,
      deleted,
      createdAt,
      updatedAt,
    }

    return eventBase
  }

  parseToClientBase(opportunityFirebase: ClientFirebaseType) {
    const {
      uid,
      name,
      ruc,
      email,
      description,
      location,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      phone,
    } = opportunityFirebase

    const opportunityBase: ClientBaseType = {
      uid,
      name,
      ruc,
      email,
      location,
      description,
      remainder,
      contacts,
      priority,
      phone,
      updatedAt,
      createdAt,
    }

    return opportunityBase
  }

  async parseToClient(opportunityFirebase: ClientFirebaseType) {
    const {
      uid,
      name,
      ruc,
      email,
      description,
      location,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      events,
    } = opportunityFirebase

    const eventsData: EventBaseType[] = []

    for (const eventRef of events) {
      const eventFirebaseData = await this.parseRefToEntity<EventFirebaseType>(eventRef)

      const eventData = this.paseToEventBase(eventFirebaseData)

      eventsData.push(eventData)
    }

    const opportunityData: ClientType = {
      uid,
      name,
      ruc,
      email,
      location,
      description,
      remainder,
      contacts,
      priority,
      updatedAt,
      createdAt,
      events: eventsData,
    }

    return opportunityData
  }

  async getClients(params: GetClientsType) {
    try {
      const collectionRef = collection(this.db, Collections.CLIENTS.NAME)

      const queries: QueryConstraint[] = []

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const opportunitiesSnap = await getDocs(q)

      const opportunitiesData = opportunitiesSnap.docs.map((doc) =>
        doc.data(),
      ) as ClientFirebaseType[]

      const opportunitiesBase: ClientBaseType[] = []

      for (const client of opportunitiesData) {
        opportunitiesBase.push(this.parseToClientBase(client))
      }

      return opportunitiesBase
    } catch (error) {
      Logger.error('Error getting opportunities', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_CLIENTS)
    }
  }

  async getClientById(id: string) {
    try {
      const dataByID = queryClient.getQueryData<ClientBaseType>([QueryKeys.GET_CLIENT_KEY, id])

      if (dataByID) {
        return dataByID
      }

      const results = queryClient.getQueriesData<ClientBaseType[]>({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_CLIENTS_KEY,
      })

      const data = this.findItemOnResults(results, id)

      if (data) {
        return data
      }

      const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

      const opportunitySnap = await getDoc(docRef)

      const opportunityFirebaseData = opportunitySnap.data() as ClientFirebaseType

      return this.parseToClientBase(opportunityFirebaseData)
    } catch (error) {
      Logger.error('Error getting client by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_CLIENTS)
    }
  }

  async createClient(data: CreateClientType) {
    try {
      const currentTimestamp = moment().unix()

      const newClient: ClientFirebaseType = {
        ...data,
        uid: uuidv4(),
        updatedAt: currentTimestamp,
        createdAt: currentTimestamp,
        events: [],
      }

      await setDoc(doc(this.db, Collections.CLIENTS.NAME, newClient.uid), newClient)

      const clientData: ClientBaseType = {
        uid: newClient.uid,
        name: newClient.name,
        ruc: newClient.ruc,
        email: newClient.email,
        location: newClient.location,
        description: newClient.description,
        remainder: newClient.remainder,
        contacts: newClient.contacts,
        priority: newClient.priority,
        updatedAt: newClient.updatedAt,
        createdAt: newClient.createdAt,
      }

      return clientData
    } catch (error) {
      Logger.error('Error creating client', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_CLIENT)
    }
  }

  async updateClient(data: UpdateClientType) {
    try {
      const updateClient: Partial<ClientFirebaseType> = {}

      if (data.name) {
        updateClient.name = data.name
      }

      if (data.ruc) {
        updateClient.ruc = data.ruc
      }

      if (data.email) {
        updateClient.email = data.email
      }

      if (data.location) {
        updateClient.location = data.location
      }

      if (data.remainder) {
        updateClient.remainder = data.remainder
      }

      if (data.contacts) {
        updateClient.contacts = data.contacts
      }

      if (data.priority) {
        updateClient.priority = data.priority
      }

      if (Object.keys(updateClient).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      const docRef = doc(this.db, Collections.CLIENTS.NAME, data.uid)

      await updateDoc(docRef, updateClient)
    } catch (error) {
      Logger.error('Error updating client', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_CLIENT)
    }
  }

  async deleteClient(id: string) {
    try {
      const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting client', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_CLIENT)
    }
  }

  async deleteClients(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      ids.forEach((id) => {
        const docRef = doc(this.db, Collections.CLIENTS.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting opportunities', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_CLIENTS)
    }
  }

  //Events
  async getEvents(params: GetEventsType) {
    try {
      const collectionRef = collection(this.db, Collections.EVENTS.NAME)

      const queries: QueryConstraint[] = [
        where('client', '==', doc(this.db, Collections.CLIENTS.NAME, params.clientID)),
      ]

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const eventsSnap = await getDocs(q)

      const eventsData = eventsSnap.docs.map((doc) => doc.data()) as EventFirebaseType[]

      const eventsBase: EventBaseType[] = []

      for (const event of eventsData) {
        eventsBase.push(this.paseToEventBase(event))
      }

      return eventsBase
    } catch (error) {
      Logger.error('Error getting events', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_EVENTS)
    }
  }

  async createEvent(params: CreateEventType) {
    try {
      const newEventBase: EventBaseType = await runTransaction(this.db, async (transaction) => {
        const { clientID, data } = params

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        const clientSnap = await transaction.get(clientRef)

        const clientData = clientSnap.data() as ClientFirebaseType

        const currentTimestamp = moment().unix()

        const newEvent: EventFirebaseType = {
          ...data,
          uid: uuidv4(),
          updatedAt: currentTimestamp,
          createdAt: currentTimestamp,
          client: doc(this.db, Collections.CLIENTS.NAME, clientID),
        }

        transaction.set(doc(this.db, Collections.EVENTS.NAME, newEvent.uid), newEvent)

        const events = clientData.events ?? []

        events.push(doc(this.db, Collections.EVENTS.NAME, newEvent.uid))

        transaction.update(clientRef, { events })

        return this.paseToEventBase(newEvent)
      })

      return newEventBase
    } catch (error) {
      Logger.error('Error creating event', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_EVENT)
    }
  }

  async updateEvent(params: UpdateEventType) {
    try {
      const { clientID, data } = params

      await runTransaction(this.db, async (transaction) => {
        const docRef = doc(this.db, Collections.EVENTS.NAME, data.uid)

        const updateEvent: Partial<EventFirebaseType> = {}

        if (data.name) {
          updateEvent.name = data.name
        }

        if (data.description) {
          updateEvent.description = data.description
        }

        if (data.amount) {
          updateEvent.amount = data.amount
        }

        if (data.attachments) {
          updateEvent.attachments = data.attachments
        }

        if (data.date) {
          updateEvent.date = data.date
        }

        if (Object.keys(updateEvent).length === 0) {
          throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
        }

        transaction.update(docRef, updateEvent)

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        transaction.update(clientRef, { updatedAt: moment().unix() })
      })
    } catch (error) {
      Logger.error('Error updating event', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_EVENT)
    }
  }

  async deleteEvent(id: string) {
    try {
      await runTransaction(this.db, async (transaction) => {
        const docRef = doc(this.db, Collections.EVENTS.NAME, id)

        const eventSnap = await transaction.get(docRef)

        const event = eventSnap.data() as EventFirebaseType

        const clientSnap = await transaction.get(event.client)

        const clientData = clientSnap.data() as ClientFirebaseType

        const events = clientData.events ?? []

        const index = events.findIndex((e) => e.id === id)

        if (index !== -1) {
          events.splice(index, 1)
        }

        transaction.update(event.client, { events })

        transaction.update(docRef, { deleted: true, updatedAt: moment().unix() })
      })
    } catch (error) {
      Logger.error('Error deleting event', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_EVENT)
    }
  }

  async deleteEvents(parmas: DeleteEventsType) {
    try {
      const { clientID, uids } = parmas
      await runTransaction(this.db, async (transaction) => {
        const eventSnaps: DocumentSnapshot[] = []

        for (const id of uids) {
          const docRef = doc(this.db, Collections.EVENTS.NAME, id)

          const eventSnap = await transaction.get(docRef)

          eventSnaps.push(eventSnap)
        }

        const clientRef = doc(this.db, Collections.CLIENTS.NAME, clientID)

        const clientSnap = await transaction.get(clientRef)

        const clientData = clientSnap.data() as ClientFirebaseType

        const events = clientData.events ?? []

        const eventsFiltered = events.filter((e) => !uids.includes(e.id))

        transaction.update(clientRef, { events: eventsFiltered })

        for (const eventSnap of eventSnaps) {
          transaction.delete(eventSnap.ref)
        }
      })
    } catch (error) {
      Logger.error('Error deleting events', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_EVENTS)
    }
  }

  //Proforma
  async getIdProforma() {
    try {
      return await runTransaction(this.db, async (transaction) => {
        const docRef = doc(this.db, Collections.COUNTERS.NAME, 'proforma')

        const docSnap = await transaction.get(docRef)

        let counterData: SequentialProformaType = { sequential: 0 }

        const exists = docSnap.exists()

        if (exists) {
          counterData = docSnap.data() as SequentialProformaType
        }

        const newSequential = (counterData.sequential ?? 0) + 1

        const newData: SequentialProformaType = { sequential: newSequential }

        if (exists) {
          transaction.update(docRef, newData)
        } else {
          transaction.set(docRef, newData)
        }

        return newSequential
      })
    } catch (error) {
      Logger.error('Error getting id proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_ID_PROFORMA)
    }
  }

  async parseToProformaBase(proformaFirebase: ProformaFirebaseType) {
    const {
      uid,
      sequential,
      name,
      description,
      clientRef,
      items,
      status,
      updatedAt,
      createdAt,
      dueDate,
      iva,
      payments,
      validUntil,
      warranty,
    } = proformaFirebase

    let client: CustomerProformaType | undefined | null = proformaFirebase.client

    if (clientRef) {
      const clientData = await this.parseRefToEntity<ClientFirebaseType>(clientRef)
      client = {
        uid: clientData.uid,
        ruc: clientData.ruc ?? '',
        name: clientData.name,
        email: clientData.email,
        phone: clientData.phone,
        location: clientData.location,
      }
    }

    const proformaBase: ProformaBaseType = {
      uid,
      sequential,
      name,
      description,
      items,
      updatedAt,
      createdAt,
      status,
      client: client!,
      dueDate,
      iva,
      payments,
      validUntil,
      warranty,
    }

    return proformaBase
  }

  async getProformas(params: GetProformasType) {
    try {
      const collectionRef = collection(this.db, Collections.PROFORMAS.NAME)

      const queries: QueryConstraint[] = []

      if (params.cursorId) {
        const docRef = doc(collectionRef, params.cursorId)
        queries.push(startAfter(docRef))
        params.limit && queries.push(limit(params.limit))
      }

      queries.push(orderBy('updatedAt', params.orderDir ?? 'desc'))

      const q = query(collectionRef, ...queries)

      const proformasSnap = await getDocs(q)

      const proformasData = proformasSnap.docs.map((doc) => doc.data()) as ProformaFirebaseType[]

      const proformasBase: ProformaBaseType[] = []

      for (const proforma of proformasData) {
        proformasBase.push(await this.parseToProformaBase(proforma))
      }

      return proformasBase
    } catch (error) {
      Logger.error('Error getting proformas', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_PROFORMAS)
    }
  }

  async getProformaById(id: string) {
    try {
      const dataByID = queryClient.getQueryData<ProformaBaseType>([QueryKeys.GET_PROFORMA_KEY, id])

      if (dataByID) {
        return dataByID
      }

      const results = queryClient.getQueriesData<ProformaBaseType[]>({
        predicate: (query) => query.queryKey[0] === QueryKeys.GET_PROFORMAS_KEY,
      })

      const data = this.findItemOnResults(results, id)

      if (data) {
        return data
      }

      const docRef = doc(this.db, Collections.PROFORMAS.NAME, id)

      const proformaSnap = await getDoc(docRef)

      const proformaFirebaseData = proformaSnap.data() as ProformaFirebaseType

      return this.parseToProformaBase(proformaFirebaseData)
    } catch (error) {
      Logger.error('Error getting proforma by id', error)
      throw ErrorService.get(ErrorCodes.ERROR_GETTING_PROFORMAS)
    }
  }

  async createProforma(data: CreateProformaType) {
    try {
      const currentTimestamp = moment().unix()

      const sequential = await this.getIdProforma()

      const newProforma: ProformaFirebaseType = {
        uid: uuidv4(),
        sequential,
        name: data.name,
        description: data.description,
        status: data.status,
        items: data.items,
        createdAt: currentTimestamp,
        updatedAt: currentTimestamp,
        client: data.client,
        dueDate: data.dueDate,
        iva: data.iva,
        payments: data.payments,
        validUntil: data.validUntil,
        warranty: data.warranty,
      }

      if (data.clientID) {
        newProforma.clientRef = doc(this.db, Collections.CLIENTS.NAME, data.clientID)
      } else {
        newProforma.client = data.client
      }

      await setDoc(doc(this.db, Collections.PROFORMAS.NAME, newProforma.uid), newProforma)

      let client: CustomerProformaType | null = null

      if (newProforma.clientRef) {
        const clientData = await this.parseRefToEntity<ClientFirebaseType>(newProforma.clientRef)
        client = {
          uid: clientData.uid,
          ruc: clientData.ruc ?? '',
          name: clientData.name,
          email: clientData.email,
          phone: clientData.phone,
          location: clientData.location,
        }
      } else if (newProforma.client) {
        client = newProforma.client
      }

      const proformaData: ProformaBaseType = {
        ...newProforma,
        client: client!,
      }

      return proformaData
    } catch (error) {
      Logger.error('Error creating proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_CREATING_PROFORMA)
    }
  }

  async updateProforma(data: UpdateProformaType) {
    try {
      const updateProforma: Partial<ProformaFirebaseType> = {}

      if (data.name) {
        updateProforma.name = data.name
      }

      if (data.description) {
        updateProforma.description = data.description
      }

      if (data.clientID) {
        updateProforma.clientRef = doc(this.db, Collections.CLIENTS.NAME, data.clientID)
        updateProforma.client = null
      } else {
        updateProforma.clientRef = null
        updateProforma.client = data.client
      }

      if (data.items) {
        updateProforma.items = data.items
      }

      if (data.status) {
        updateProforma.status = data.status
      }

      if (data.dueDate) {
        updateProforma.dueDate = data.dueDate
      }

      if (data.iva) {
        updateProforma.iva = data.iva
      }

      if (data.payments) {
        updateProforma.payments = data.payments
      }

      if (data.validUntil) {
        updateProforma.validUntil = data.validUntil
      }

      if (data.warranty) {
        updateProforma.warranty = data.warranty
      }

      if (Object.keys(updateProforma).length === 0) {
        throw ErrorService.get(ErrorCodes.ERROR_NOT_FIELDS_TO_UPDATE)
      }

      const docRef = doc(this.db, Collections.PROFORMAS.NAME, data.uid)

      await updateDoc(docRef, updateProforma)
    } catch (error) {
      Logger.error('Error updating proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_UPDATING_PROFORMA)
    }
  }

  async deleteProforma(id: string) {
    try {
      const docRef = doc(this.db, Collections.PROFORMAS.NAME, id)

      await deleteDoc(docRef)
    } catch (error) {
      Logger.error('Error deleting proforma', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_PROFORMA)
    }
  }

  async deleteProformas(ids: string[]) {
    try {
      const batch = writeBatch(this.db)

      ids.forEach((id) => {
        const docRef = doc(this.db, Collections.PROFORMAS.NAME, id)

        batch.delete(docRef)
      })

      await batch.commit()
    } catch (error) {
      Logger.error('Error deleting proformas', error)
      throw ErrorService.get(ErrorCodes.ERROR_DELETING_PROFORMAS)
    }
  }
}

export default FirebaseDS
