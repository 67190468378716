export const RoutesApp = {
  LOGIN: '/login',
  REGISTER: '/register',
  //Private
  HOME: '/home/:action?',
  RAW_MATERIALS: '/materia-primas',
  WAREHOUSE: '/bodega',
  RECEIPTS: '/recetas',
  BLOCK: '/bloque',
  CLIENTS: '/clientes',
  CLIENT: '/clientes/:uid',
  PROFORMAS: '/proformas',
  PROFORMA_FORM: '/proformas/form/:uid?',
  PROFORMA_VIEW: '/proformas/view/:uid',
  GEO_CHECKPOINTS: '/geolocalizaciones',
  //Public
  ROOT: '/',
}
