import { SignInType, SignUpType, UserType } from '@customTypes/user'
import {
  RawMaterialWarehouseType,
  CreateRawMaterialBaseType,
  UpdateRawMaterialBaseType,
  GetRawMaterialsType,
  RawMaterialBaseType,
  GetRawMaterialWarehouseType,
  DeleteRawMaterialType,
  DeleteRawMaterialsType,
  GetRawMaterialsWarehouseType,
} from '@customTypes/rawMaterial'
import {
  CreateReceiptType,
  DeleteRawMaterialsFromReceiptType,
  GetReceiptsType,
  ReceiptBaseType,
  ReceiptType,
  UpdateReceiptType,
} from '@customTypes/receipt'
import {
  CommitBaseType,
  CommitType,
  CreateCommitType,
  GetCommitsType,
  UpdateCommitType,
} from '@customTypes/commit'
import {
  CreateWarehouseType,
  DeleteRawMaterialsFromWarehouseType,
  GetWarehousesType,
  MoveRawMaterialsType,
  UpdateWarehouseType,
  WarehouseBaseType,
  WarehouseType,
} from '@customTypes/warehouse'
import {
  BlockBaseType,
  BlockType,
  CreateBlockType,
  DeleteReceiptsBlockType,
  GetBlocksType,
  UpdateBlockType,
} from '@customTypes/block'
import {
  CreateClientType,
  GetClientsType,
  ClientBaseType,
  UpdateClientType,
} from '@customTypes/client'
import {
  CreateProformaType,
  GetProformasType,
  ProformaBaseType,
  UpdateProformaType,
} from '@customTypes/proforma'
import {
  CreateEventType,
  DeleteEventsType,
  EventBaseType,
  GetEventsType,
  UpdateEventType,
} from '@customTypes/event'
import {
  CreateGeoCheckpointType,
  GeoCheckpointBaseType,
  GetGeoCheckpointType,
} from '@customTypes/geoCheckpoint'

abstract class DataRepo {
  abstract signinWithEmailAndPassword(params: SignInType): Promise<UserType>

  abstract signUpWithEmailAndPassword(params: SignUpType): Promise<UserType>

  abstract getUser(): Promise<UserType | null>

  abstract getUsers(): Promise<UserType[]>

  abstract logout(): Promise<void>

  abstract deleteAccount(): Promise<void>

  abstract saveUserGeoCheckpoint(params: CreateGeoCheckpointType): Promise<void>

  abstract getGeoCheckpoints(params: GetGeoCheckpointType): Promise<GeoCheckpointBaseType[]>

  abstract checkBiometric(): Promise<boolean>

  //Raw material
  abstract downloadAttachment(url: string, filename: string): Promise<void>

  abstract getRawMaterials(params: GetRawMaterialsType): Promise<RawMaterialBaseType[]>

  abstract getRawMaterialById(
    params: GetRawMaterialWarehouseType,
  ): Promise<RawMaterialWarehouseType>

  abstract getRawMaterialBaseById(id: string): Promise<RawMaterialBaseType>

  abstract createRawMaterial(params: CreateRawMaterialBaseType): Promise<RawMaterialBaseType>

  abstract updateRawMaterial(params: UpdateRawMaterialBaseType): Promise<void>

  abstract deleteRawMaterial(params: DeleteRawMaterialType): Promise<void>

  abstract deleteRawMaterials(params: DeleteRawMaterialsType): Promise<void>

  //Warehouse
  abstract getWarehouses(params: GetWarehousesType): Promise<WarehouseBaseType[]>

  abstract getMaterialsByWarehouse(
    params: GetRawMaterialsWarehouseType,
  ): Promise<RawMaterialWarehouseType[]>

  abstract getWarehouseById(id: string): Promise<WarehouseType>

  abstract createWarehouse(params: CreateWarehouseType): Promise<WarehouseType>

  abstract updateWarehouse(params: UpdateWarehouseType): Promise<void>

  abstract deleteWarehouse(id: string): Promise<void>

  abstract deleteWarehouses(ids: string[]): Promise<void>

  abstract moveRawMaterials(params: MoveRawMaterialsType): Promise<void>

  abstract deleteRawMaterialsFromWarehouse(
    params: DeleteRawMaterialsFromWarehouseType,
  ): Promise<void>

  //Commits
  abstract getCommits(params: GetCommitsType): Promise<CommitBaseType[]>

  abstract getCommitById(id: string): Promise<CommitType>

  abstract createCommit(params: CreateCommitType): Promise<CommitBaseType>

  abstract updateCommit(params: UpdateCommitType): Promise<void>

  abstract deleteCommit(id: string): Promise<void>

  abstract deleteCommits(ids: string[]): Promise<void>

  //Receipts
  abstract getReceipts(params: GetReceiptsType): Promise<ReceiptBaseType[]>

  abstract getReceiptsExtended(params: GetReceiptsType): Promise<ReceiptType[]>

  abstract getReceiptById(id: string): Promise<ReceiptType>

  abstract createReceipt(params: CreateReceiptType): Promise<ReceiptType>

  abstract updateReceipt(params: UpdateReceiptType): Promise<void>

  abstract deleteReceipt(id: string): Promise<void>

  abstract deleteReceipts(ids: string[]): Promise<void>

  abstract deleteRawMaterialsFromReceipt(params: DeleteRawMaterialsFromReceiptType): Promise<void>

  //Blocks
  abstract getBlocks(params: GetBlocksType): Promise<BlockBaseType[]>

  abstract getBlocksExtended(params: GetBlocksType): Promise<BlockType[]>

  abstract getBlockById(id: string): Promise<BlockType>

  abstract createBlock(params: CreateBlockType): Promise<BlockType>

  abstract updateBlock(params: UpdateBlockType): Promise<void>

  abstract deleteBlock(id: string): Promise<void>

  abstract deleteBlocks(ids: string[]): Promise<void>

  abstract deleteReceiptsFromBlock(params: DeleteReceiptsBlockType): Promise<void>

  //Clients
  abstract getClients(params: GetClientsType): Promise<ClientBaseType[]>

  abstract getClientById(id: string): Promise<ClientBaseType>

  abstract createClient(client: CreateClientType): Promise<ClientBaseType>

  abstract updateClient(client: UpdateClientType): Promise<void>

  abstract deleteClient(id: string): Promise<void>

  abstract deleteClients(ids: string[]): Promise<void>

  abstract getEvents(params: GetEventsType): Promise<EventBaseType[]>

  abstract createEvent(params: CreateEventType): Promise<EventBaseType>

  abstract updateEvent(params: UpdateEventType): Promise<void>

  abstract deleteEvent(id: string): Promise<void>

  abstract deleteEvents(params: DeleteEventsType): Promise<void>

  //Proformas
  abstract getProformas(params: GetProformasType): Promise<ProformaBaseType[]>

  abstract getProformaById(id: string): Promise<ProformaBaseType>

  abstract createProforma(data: CreateProformaType): Promise<ProformaBaseType>

  abstract updateProforma(data: UpdateProformaType): Promise<void>

  abstract deleteProforma(id: string): Promise<void>

  abstract deleteProformas(ids: string[]): Promise<void>
}

export default DataRepo
