import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { GetEntitySchema } from './network'
import { RawMaterialBaseSchema } from './rawMaterial'

// Used when get all warehouses
export const WarehouseBaseSchema = z.object({
  uid: z.string(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(100, 'Descripción debe tener como máximo 100 caracteres').trim(),
  inHouse: z.boolean(),
  deleted: z.boolean().optional(),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type WarehouseBaseType = z.infer<typeof WarehouseBaseSchema>

// Used when get a warehouse by id (example: show materials by warehouse)
export const WarehouseSchema = WarehouseBaseSchema.extend({
  rawMaterials: z.array(z.lazy(() => RawMaterialBaseSchema.extend({ stock: z.number() }))),
})

export type WarehouseType = z.infer<typeof WarehouseSchema>

// Internal use
export const WarehouseFirebaseSchema = WarehouseSchema.omit({
  rawMaterials: true,
}).extend({
  rawMaterials: z.array(z.custom<DocumentReference>()),
})

export type WarehouseFirebaseType = z.infer<typeof WarehouseFirebaseSchema>

//
// CRUD OPERATIONS
//
//Create
export const CreateWarehouseSchema = WarehouseSchema.omit({
  uid: true,
  deleted: true,
  updatedAt: true,
  createdAt: true,
})

export type CreateWarehouseType = z.infer<typeof CreateWarehouseSchema>

//Update
export const UpdateWarehouseSchema = WarehouseSchema.partial().extend({
  uid: z.string(),
})

export type UpdateWarehouseType = z.infer<typeof UpdateWarehouseSchema>

// Get warehouses
export const GetWarehousesSchema = GetEntitySchema

export type GetWarehousesType = z.infer<typeof GetWarehousesSchema>

//Move raw materials
export const MoveRawMaterialsSchema = z.object({
  warehouseFromID: z.string(),
  warehouseToID: z.string(),
  rawMaterials: z.array(
    z.object({
      rawMaterialID: z.string(),
      quantity: z.number().positive('La cantidad debe ser mayor a 0'),
    }),
  ),
})

export type MoveRawMaterialsType = z.infer<typeof MoveRawMaterialsSchema>

//Delete raw materials from warehouse
export const DeleteRawMaterialsFromWarehouseSchema = z.object({
  warehouseID: z.string(),
  rawMaterialIDs: z.array(z.string()).min(1, 'Debe seleccionar al menos un material'),
})

export type DeleteRawMaterialsFromWarehouseType = z.infer<
  typeof DeleteRawMaterialsFromWarehouseSchema
>
