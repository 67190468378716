import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { FORMAT_ID } from '@constants/receipt'

import { RawMaterialBaseSchema } from './rawMaterial'
import { GetEntitySchema } from './network'

export const RawMaterialReceiptSchema = RawMaterialBaseSchema.extend({
  quantity: z.number().gt(0, 'La cantidad debe ser mayor a 0'),
})

export type RawMaterialReceiptType = z.infer<typeof RawMaterialReceiptSchema>

//Used when get all receipts
export const ReceiptBaseSchema = z.object({
  uid: z
    .string()
    .regex(
      FORMAT_ID,
      'ID debe ser en mayúsculas y opcionalmente seguido de un guión y números. Ej: AB, AB-1',
    )
    .toUpperCase(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(200, 'Descripción debe tener como máximo 200 caracteres').trim(),
  descriptionProforma: z
    .string()
    .max(750, 'Descripción de proforma debe tener como máximo 750 caracteres')
    .trim()
    .optional()
    .nullable(),
  deleted: z.boolean().optional(),
  updatedAt: z.number(),
  createdAt: z.number(),
})

export type ReceiptBaseType = z.infer<typeof ReceiptBaseSchema>

// Used when get a receipt by id
export const ReceiptSchema = ReceiptBaseSchema.extend({
  rawMaterials: z.array(RawMaterialReceiptSchema).min(1, 'Debe tener al menos un material'),
})

export type ReceiptType = z.infer<typeof ReceiptSchema>

// Internal use
export const ReceiptsFirebaseSchema = ReceiptSchema.omit({
  rawMaterials: true,
}).extend({
  rawMaterials: z.array(z.custom<DocumentReference>()),
})

export type ReceiptsFirebaseType = z.infer<typeof ReceiptsFirebaseSchema>

//Create
export const CreateReceiptSchema = ReceiptSchema.omit({
  deleted: true,
  updatedAt: true,
  createdAt: true,
  rawMaterials: true,
}).extend({
  rawMaterials: z.array(RawMaterialReceiptSchema),
})

export type CreateReceiptType = z.infer<typeof CreateReceiptSchema>

//Update
export const UpdateReceiptSchema = z.object({
  current: CreateReceiptSchema.partial(),
  previous: CreateReceiptSchema.partial().extend({
    uid: z.string(),
  }),
})

export type UpdateReceiptType = z.infer<typeof UpdateReceiptSchema>

// Get receipts
export const GetReceiptsSchema = GetEntitySchema

export type GetReceiptsType = z.infer<typeof GetReceiptsSchema>

//Delete raw materials from receipt
export const DeleteRawMaterialsFromReceiptSchema = z.object({
  receiptID: z.string(),
  rawMaterialIDs: z.array(z.string()).min(1, 'Debe tener al menos un material'),
})

export type DeleteRawMaterialsFromReceiptType = z.infer<typeof DeleteRawMaterialsFromReceiptSchema>
