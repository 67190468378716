import z from 'zod'
import { DocumentReference } from 'firebase/firestore'

import { DueDateOptions, Entities, StatusProforma } from '@constants/proforma'

import { BlockSchema, ReceiptBlockSchema } from './block'
import { RawMaterialReceiptSchema } from './receipt'

import { GetEntitySchema } from './network'
import { ClientBaseSchema } from './client'

export const SequentialProformaSchema = z.object({
  sequential: z.number(),
})

export type SequentialProformaType = z.infer<typeof SequentialProformaSchema>

export const BlockProformaSchema = BlockSchema.extend({
  quantity: z.number().gt(0, 'La cantidad debe ser mayor a 0'),
})

export type BlockProformaType = z.infer<typeof BlockProformaSchema>

export const StatusSchema = z.custom<keyof typeof StatusProforma>(
  (value) => Object.keys(StatusProforma).includes(value),
  {
    message: 'Estado inválido',
  },
)

export const DueDateOptionsSchema = z.custom<keyof typeof DueDateOptions>(
  (value) => Object.keys(DueDateOptions).includes(value),
  {
    message: 'Opción inválida',
  },
)

export type StatusType = z.infer<typeof StatusSchema>

export const CustomerProformaSchema = ClientBaseSchema.pick({
  uid: true,
  ruc: true,
  name: true,
  email: true,
  phone: true,
  location: true,
}).partial({
  uid: true,
  email: true,
  phone: true,
  location: true,
})

export type CustomerProformaType = z.infer<typeof CustomerProformaSchema>

export const TypeItemProforma = z.custom<keyof typeof Entities>(
  (value) => Object.keys(Entities).includes(value),
  {
    message: 'Tipo de item inválido',
  },
)

export type TypeItemProformaType = z.infer<typeof TypeItemProforma>

export const AccessoryItemSchema = z.object({
  uid: z.string(),
  name: z.string(),
  description: z.string(),
  uidItem: z.string(),
  type: TypeItemProforma,
  disabled: z.boolean().optional().nullable(),
  quantity: z.number().min(0, 'Debe ser positivo').max(100, 'El valor máximo es 100%'),
})

export type AccessoryItemType = z.infer<typeof AccessoryItemSchema>

export const InnerProformaBaseSchema = z.object({
  uid: z.string(),
  sequential: z.number(),
  name: z
    .string()
    .min(3, 'Nombre debe tener al menos 3 caracteres')
    .max(50, 'Nombre debe tener como máximo 50 caracteres')
    .trim(),
  description: z.string().max(1000, 'Descripción debe tener como máximo 1000 caracteres').trim(),
  status: StatusSchema,
  updatedAt: z.number(),
  createdAt: z.number(),
  //client fields
  client: CustomerProformaSchema,
  items: z
    .array(
      BlockProformaSchema.extend({
        type: TypeItemProforma,
        disabled: z.boolean().optional().nullable(),
      })
        .or(
          ReceiptBlockSchema.extend({
            type: TypeItemProforma,
            disabled: z.boolean().optional().nullable(),
          }),
        )
        .or(
          RawMaterialReceiptSchema.extend({
            type: TypeItemProforma,
            disabled: z.boolean().optional().nullable(),
          }),
        )
        .or(AccessoryItemSchema),
    )
    .min(1, 'Se debe agregar al menos un item'),
  //metadata
  iva: z.number().min(0, 'Debe ser positivo').max(100, 'El valor máximo es 100%'),
  dueDate: z.object({
    unit: DueDateOptionsSchema,
    amount: z.number(),
  }),
  validUntil: z.object({
    unit: DueDateOptionsSchema,
    amount: z.number(),
  }),
  payments: z.array(
    z.object({
      percentage: z.number().min(0, 'Debe ser positivo').max(100, 'El valor máximo es 100%'),
      text: z.string().max(100, 'Descripción debe tener como máximo 100 caracteres').trim(),
    }),
  ),
  warranty: z.string().max(300, 'Garantía debe tener como máximo 300 caracteres').trim(),
})

// Items Table
export const ItemTableProformaSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  amount: z.number().min(0),
  value: z.number().min(0),
})

export type ItemTableProformaType = z.infer<typeof ItemTableProformaSchema>

export type ProformaBaseType = z.infer<typeof InnerProformaBaseSchema>

export const ProformaFirebaseSchema = InnerProformaBaseSchema.extend({
  clientRef: z.custom<DocumentReference>().optional().nullable(),
  client: CustomerProformaSchema.optional().nullable(),
})

export type ProformaFirebaseType = z.infer<typeof ProformaFirebaseSchema>

//Create
export const CreateProformaSchema = InnerProformaBaseSchema.omit({
  uid: true,
  updatedAt: true,
  createdAt: true,
  sequential: true,
})
  .extend({
    clientID: z.string(),
  })
  .superRefine(({ payments }, ctx) => {
    const totalPayments = payments.reduce((acc, payment) => acc + payment.percentage, 0)
    if (totalPayments !== 100) {
      ctx.addIssue({
        code: 'custom',
        path: ['payments'],
        message: 'La suma de los pagos debe ser igual a 100%',
      })
    }
  })

export type CreateProformaType = z.infer<typeof CreateProformaSchema>

//Update
export const UpdateProformaSchema = InnerProformaBaseSchema.omit({
  updatedAt: true,
  createdAt: true,
  sequential: true,
}).extend({
  clientID: z.string(),
})

export type UpdateProformaType = z.infer<typeof UpdateProformaSchema>

//Get proformas
export const GetProformasSchema = GetEntitySchema.extend({
  startDate: z.number().optional(),
  endDate: z.number().optional(),
})

export type GetProformasType = z.infer<typeof GetProformasSchema>
