import { CACHED_TIME } from '@constants/app'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  mutationCache: new MutationCache(),
})

queryClient.setDefaultOptions({
  queries: {
    retry: 3,
    gcTime: CACHED_TIME,
    //staleTime: STALE_TIME,
    networkMode: 'online',
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  },
  mutations: {
    retry: 1,
    gcTime: 0,
    networkMode: 'online',
  },
})

export default queryClient
