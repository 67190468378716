/* eslint-disable import/no-named-as-default */
import React, { Suspense } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from '@mantine/form'
import { useEditor } from '@tiptap/react'
import { useListState } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import { zodResolver } from 'mantine-form-zod-resolver'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ActionIcon, Button, Container, Fieldset, Menu, Table, Text } from '@mantine/core'

import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import SubScript from '@tiptap/extension-subscript'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'

import {
  AccessoryItemType,
  ProformaBaseType,
  UpdateProformaType,
  CreateProformaType,
  CreateProformaSchema,
} from '@customTypes/proforma'
import { BlockType } from '@customTypes/block'
import { ReceiptType } from '@customTypes/receipt'
import { ClientBaseType } from '@customTypes/client'
import { RawMaterialBaseType } from '@customTypes/rawMaterial'

import DataRepo from '@api/datasource/data'
import queryProforma from '@api/datasource/query'

import QueryKeys from '@constants/queryKeys'
import { RoutesApp } from '@constants/routes'
import { StatusProforma, Entities, DueDateOptions } from '@constants/proforma'

import { useIsMobile, useTableMinWidth } from '@hooks/mobile'

import { $ } from '@utils/styles'
import { ErrorService } from '@utils/error'
import { buildSelectOptions, filterSelect } from '@utils/form'
import { getDescription, getProformaTotal } from '@utils/proforma'
import { invalidateDataQuery, isLoadingMutation, isLoadingOrRefetchQuery } from '@utils/network'

import Input from '@components/shared/input'
import LoaderText from '@components/shared/loader'
import NumberFormat from '@components/shared/Number'
import TableCustom, { GenericColumnType } from '@components/shared/table'
import ConfirmationModal from '@components/shared/confirmationModal'
import { IconX } from '@tabler/icons-react'

//import TextEditor from '@components/shared/richTextEditor'

const TextEditor = React.lazy(() => import('@components/shared/richTextEditor'))

const INITIAL: CreateProformaType = {
  name: '',
  description:
    '<p><strong>Trabajos incluidos:</strong></p><ul><li><p>Trabajo 1</p></li></ul><p><strong>Requisitos para el trabajo:</strong></p><ul><li><p>Requisito 1</p></li></ul><p></p>',
  status: 'draft',
  items: [],
  clientID: '',
  client: {
    name: '',
    email: '',
    phone: '',
    location: '',
    ruc: '',
  },
  dueDate: {
    amount: 7,
    unit: 'days',
  },
  iva: 15,
  payments: [
    {
      text: '',
      percentage: 0,
    },
  ],
  validUntil: {
    amount: 30,
    unit: 'days',
  },
  warranty: '',
}

type Mode = 'create' | 'edit'

type SourceDataType = Record<string, ProformaBaseType['items'][0]>

const ProformaForm = () => {
  const navigate = useNavigate()

  const { uid } = useParams<{ uid: string }>()

  const mode: Mode = uid ? 'edit' : 'create'

  const isMobile = useIsMobile()

  const [confirmExit, setConfirmExit] = React.useState(false)

  const [listItem, handlers] = useListState<string>([])

  const [clientSearch, setClientSearch] = React.useState<string | null | undefined>(null)

  const [itemSearch, setItemSearch] = React.useState<string | null>(null)

  const minWidth = useTableMinWidth(isMobile)

  const proformaDataQuery = useQuery<ProformaBaseType, ErrorService, ProformaBaseType, string[]>({
    enabled: mode === 'edit',
    queryKey: [QueryKeys.GET_PROFORMA_KEY, String(uid)],
    queryFn: async ({ queryKey }) => {
      const response = await DataRepo.getProformaById(queryKey[1])

      return response
    },
  })

  const clientsQuery = useQuery<ClientBaseType[], ErrorService, ClientBaseType[]>({
    queryKey: [QueryKeys.GET_CLIENTS_KEY],
    queryFn: async () => {
      const response = await DataRepo.getClients({})

      return response
    },
  })

  const formProforma = useForm<CreateProformaType>({
    initialValues: INITIAL,
    validate: zodResolver(CreateProformaSchema),
  })

  const editorDescription = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: formProforma.values.description,
  })

  const { items: itemsForm } = formProforma.getValues()

  const rawMaterialsQuery = useQuery<RawMaterialBaseType[], ErrorService, RawMaterialBaseType[]>({
    initialData: [],
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_MATERIALS_KEY],
    queryFn: async () => {
      const response = await DataRepo.getRawMaterials({})

      return response
    },
  })

  const receiptsQuery = useQuery<ReceiptType[], ErrorService, ReceiptType[]>({
    initialData: [],
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_RECEIPTS_KEY],
    queryFn: async () => {
      const response = await DataRepo.getReceiptsExtended({})
      return response
    },
  })

  const blocksQuery = useQuery<BlockType[], ErrorService, BlockType[]>({
    initialData: [],
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_BLOCKS_KEY],
    queryFn: async () => {
      const response = await DataRepo.getBlocksExtended({})
      return response
    },
  })

  const sourceDataQuery = useQuery<
    SourceDataType,
    ErrorService,
    SourceDataType,
    [string, RawMaterialBaseType[], ReceiptType[], BlockType[], ProformaBaseType['items']]
  >({
    gcTime: 0,
    staleTime: 0,
    initialData: {},
    refetchOnMount: true,
    queryKey: [
      QueryKeys.GET_SOURCE_DATA_KEY,
      rawMaterialsQuery.data,
      receiptsQuery.data,
      blocksQuery.data,
      itemsForm,
    ],
    queryFn: async ({ queryKey }) => {
      const [, rawMaterials, receipts, blocks, existingItems] = queryKey

      const existingIDs = existingItems.map(({ uid }) => uid)

      const tempItems: Record<string, ProformaBaseType['items'][0]> = {}

      if (rawMaterials.length) {
        for (const rawMaterial of rawMaterialsQuery.data) {
          tempItems[rawMaterial.uid] = {
            ...rawMaterial,
            type: 'raw-material',
            quantity: 1,
            disabled: existingIDs.includes(rawMaterial.uid),
          }
        }
      }
      if (receipts.length) {
        for (const receipt of receiptsQuery.data) {
          tempItems[receipt.uid] = {
            ...receipt,
            type: 'receipt',
            quantity: 1,
            disabled: existingIDs.includes(receipt.uid),
          }
        }
      }
      if (blocks.length) {
        for (const block of blocksQuery.data) {
          tempItems[block.uid] = {
            ...block,
            type: 'block',
            quantity: 1,
            disabled: existingIDs.includes(block.uid),
          }
        }
      }

      return tempItems
    },
  })

  const totalProformaQuery = useQuery<
    [number, number, number],
    ErrorService,
    [number, number, number],
    [string, CreateProformaType['items'], CreateProformaType['iva']]
  >({
    initialData: [0, 0, 0],
    refetchOnMount: true,
    queryKey: [QueryKeys.GET_PROFORMA_TOTAL_KEY, itemsForm, formProforma.values.iva],
    queryFn: async ({ queryKey }) => {
      const [, items, iva] = queryKey
      let total = 0

      for (const item of items) {
        total += getProformaTotal({
          item,
          itemsProforma: items,
        })
      }

      return [total, total * (iva / 100), total * (1 + iva / 100)]
    },
  })

  const proformaCreateMutation = useMutation<ProformaBaseType, ErrorService, CreateProformaType>({
    mutationFn: async (data) => {
      const response = await DataRepo.createProforma(data)

      await queryProforma.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_PROFORMAS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al crear la proforma',
        })
      }

      navigate(RoutesApp.PROFORMAS)

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Proforma creada correctamente',
      })
    },
  })

  const proformaUpdateMutation = useMutation<void, ErrorService, UpdateProformaType>({
    mutationFn: async (data) => {
      const response = await DataRepo.updateProforma(data)

      await invalidateDataQuery<ProformaBaseType>({
        queryKeys: [QueryKeys.GET_PROFORMA_KEY, String(uid)],
        exact: true,
      })

      await queryProforma.invalidateQueries({
        predicate: (query) => [QueryKeys.GET_PROFORMAS_KEY].includes(query.queryKey[0] as string),
        refetchType: 'all',
      })

      navigate(RoutesApp.PROFORMAS)

      return response
    },
    onSettled: (_, error) => {
      if (error) {
        return notifications.show({
          color: 'red',
          title: 'Error',
          message: error.message ?? 'Error al actualizar la proforma',
        })
      }

      notifications.show({
        color: 'green',
        title: 'Éxito',
        message: 'Proforma actualizada correctamente',
      })
    },
  })

  React.useEffect(() => {
    if (proformaDataQuery.data) {
      setClientSearch(proformaDataQuery.data.client.uid)

      formProforma.setValues({
        name: proformaDataQuery.data.name,
        description: proformaDataQuery.data.description,
        items: proformaDataQuery.data.items,
        status: proformaDataQuery.data.status,
        clientID: proformaDataQuery.data.client.uid,
        client: {
          ruc: proformaDataQuery.data.client.ruc,
          name: proformaDataQuery.data.client.name,
          email: proformaDataQuery.data.client.email,
          phone: proformaDataQuery.data.client.phone,
          location: proformaDataQuery.data.client.location,
        },
        dueDate: proformaDataQuery.data.dueDate,
        iva: proformaDataQuery.data.iva,
        payments: proformaDataQuery.data.payments,
        validUntil: proformaDataQuery.data.validUntil,
        warranty: proformaDataQuery.data.warranty,
      })
      formProforma.resetDirty()

      if (editorDescription) {
        if (mode == 'create') {
          const adicionalDescription = proformaDataQuery.data.items.reduce((acc, item) => {
            if ([Entities['receipt'].value, Entities['block'].value].includes(item.type)) {
              const descriptionProforma = (item as ReceiptType | BlockType).descriptionProforma
              if (descriptionProforma) {
                acc += `<p><strong>${item.name}:</strong></p>${descriptionProforma}`
              }
            }
            return acc
          }, '')

          editorDescription.commands.setContent(
            proformaDataQuery.data.description + adicionalDescription,
          )
        } else {
          editorDescription.commands.setContent(proformaDataQuery.data.description)
        }
      }
    }

    return () => {
      formProforma.setValues(INITIAL)
      formProforma.resetDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proformaDataQuery.data])

  const isLoading = isLoadingOrRefetchQuery(proformaDataQuery)

  const isLoadingForm = isLoadingMutation(proformaCreateMutation, proformaUpdateMutation)

  const isLoadingSourceData = isLoadingOrRefetchQuery(sourceDataQuery)

  const isLoadingItems = isLoadingOrRefetchQuery(rawMaterialsQuery, receiptsQuery, blocksQuery)

  const clientIDInputProps = formProforma.getInputProps('clientID')

  const itemSearchInputProps = formProforma.getInputProps('items')

  return (
    <React.Fragment>
      {!isLoading && (
        <Container
          className="cd-mt-[1rem] cd-mb-[2rem] md:cd-mt-[2rem] md:cd-pb-[4rem]"
          size={isMobile ? 'xl' : '90%'}
        >
          <form
            onSubmit={formProforma.onSubmit((values) => {
              values.description = editorDescription?.getHTML() ?? ''
              if (mode === 'edit' && proformaDataQuery.data) {
                proformaUpdateMutation.mutate({
                  ...values,
                  uid: proformaDataQuery.data.uid,
                })
              } else {
                proformaCreateMutation.mutate(values as CreateProformaType)
              }
            })}
          >
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-justify-between md:cd-flex-row">
              <Text className="cd-text-lg">
                {mode === 'edit' ? 'Editar proforma' : 'Crear proforma'}
              </Text>
              <div className="cd-flex cd-flex-col cd-gap-[0.5rem] md:cd-justify-between md:cd-flex-row">
                <Button
                  color="gray"
                  onClick={() => {
                    if (formProforma.isDirty()) {
                      setConfirmExit(true)
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  Volver
                </Button>
                <Button
                  disabled={!formProforma.isDirty()}
                  loaderProps={{ type: 'dots' }}
                  loading={isLoadingForm}
                  type="submit"
                >
                  {`${mode === 'edit' ? 'Actualizar' : 'Crear'} proforma`}
                </Button>
              </div>
            </div>
            <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] cd-mt-[1rem]">
              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Proforma">
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    className="cd-basis-[100%] md:cd-basis-[60%]"
                    label="Nombre"
                    placeholder="Ingrese el nombre de la proforma"
                    typeInput="text"
                    {...formProforma.getInputProps('name')}
                    onBlur={() => formProforma.validateField('name')}
                  />
                  <Input
                    className={$('cd-basis-[100%] md:cd-basis-[40%]')}
                    data={buildSelectOptions({
                      data: Object.values(StatusProforma),
                      label: 'label',
                      value: 'value',
                    })}
                    disabled={mode === 'create'}
                    label="Estado"
                    placeholder="Seleccione el estado de la proforma"
                    readOnly={mode === 'create'}
                    typeInput="select"
                    value={StatusProforma.draft.value}
                    {...formProforma.getInputProps('status')}
                    onBlur={() => formProforma.validateField('status')}
                  />
                </div>
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Cliente">
                <Input
                  clearable
                  searchable
                  data={buildSelectOptions({
                    data: clientsQuery.data ?? [],
                    label: 'name',
                    value: 'uid',
                  })}
                  filter={(input) =>
                    filterSelect({
                      limit: 5,
                      search: input.search,
                      options: input.options,
                    })
                  }
                  label="Buscar cliente"
                  nothingFoundMessage="No se encontraron clientes"
                  placeholder="Buscar cliente por nombre"
                  typeInput="select"
                  {...clientIDInputProps}
                  value={clientSearch}
                  onChange={(value) => handleClientChange(value)}
                />
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    className="cd-basis-[100%] md:cd-basis-1/3"
                    label="Nombre"
                    placeholder="Ingrese el nombre del cliente"
                    readOnly={formProforma.values.clientID !== ''}
                    typeInput="text"
                    {...formProforma.getInputProps('client.name')}
                    onBlur={() => formProforma.validateField('client.name')}
                  />
                  <Input
                    className="cd-basis-[100%] md:cd-basis-2/3"
                    label="Ubicación"
                    placeholder="Ingrese la ubicación del cliente"
                    readOnly={formProforma.values.clientID !== ''}
                    typeInput="text"
                    {...formProforma.getInputProps('client.location')}
                    onBlur={() => formProforma.validateField('client.location')}
                  />
                </div>
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                  <Input
                    className="cd-basis-[100%] md:cd-basis-1/3"
                    label="RUC"
                    placeholder="Ingrese el RUC del cliente"
                    readOnly={formProforma.values.clientID !== ''}
                    typeInput="text"
                    {...formProforma.getInputProps('client.ruc')}
                    onBlur={() => formProforma.validateField('client.ruc')}
                  />
                  <div className="cd-basis-[100%] md:cd-basis-2/3 cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem]">
                    <Input
                      className="cd-basis-[100%] md:cd-basis-1/2"
                      label="Email"
                      placeholder="Ingrese el email del cliente"
                      readOnly={formProforma.values.clientID !== ''}
                      typeInput="text"
                      {...formProforma.getInputProps('client.email')}
                      onBlur={() => formProforma.validateField('client.email')}
                    />
                    <Input
                      className="cd-basis-[100%] md:cd-basis-1/2"
                      label="Teléfono"
                      placeholder="Ingrese el teléfono del cliente"
                      readOnly={formProforma.values.clientID !== ''}
                      typeInput="text"
                      {...formProforma.getInputProps('client.phone')}
                      onBlur={() => formProforma.validateField('client.phone')}
                    />
                  </div>
                </div>
                <Input
                  className="cd-basis-[100%] md:cd-basis-1/3"
                  label="IVA (%)"
                  placeholder="Ingrese el IVA"
                  suffix="%"
                  typeInput="number"
                  {...formProforma.getInputProps('iva')}
                  onBlur={() => formProforma.validateField('iva')}
                />
              </Fieldset>

              <Fieldset legend="Items">
                {
                  <TableCustom<ProformaBaseType['items'][0]>
                    alwaysHeader
                    hidePagination
                    columns={buildColumns()}
                    contextMenu={buildContextMenu}
                    data={itemsForm}
                    extraRows={buildExtraRows()}
                    keyId="uid"
                    limitPage={1000}
                    minWidth={minWidth}
                    noDataMessage="Agregue items a la proforma"
                    selectedRows={listItem}
                    validateMenu={(item) => item.type !== 'accessory'}
                    onChangeSelected={handlers}
                    onDelete={(uids) => {
                      formProforma.setFieldValue(
                        'items',
                        itemsForm.filter((item) => !uids.includes(item.uid)),
                        { forceUpdate: true },
                      )
                      handlers.setState([])
                    }}
                  />
                }
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Pagos">
                {formProforma.values.payments.map((payment, idx) => (
                  <div
                    className="cd-flex cd-flex-col md:cd-flex-row cd-gap-x-[1rem] cd-gap-y-[0.5rem] md:cd-gap-y-[1rem] cd-items-end"
                    key={`payment-${idx}`}
                  >
                    <Input
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label={`Descripción del pago #${idx + 1}`}
                      placeholder="Ingrese la descripción"
                      typeInput="text"
                      {...formProforma.getInputProps(`payments.${idx}.text`)}
                      value={payment.text}
                      onBlur={() => formProforma.validateField(`payments.${idx}.text`)}
                    />
                    <Input
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      label="Porcentaje"
                      placeholder="Ingrese el porcentaje"
                      suffix="%"
                      typeInput="number"
                      {...formProforma.getInputProps(`payments.${idx}.percentage`)}
                      value={payment.percentage}
                      onBlur={() => formProforma.validateField(`payments.${idx}.percentage`)}
                    />
                    <ActionIcon
                      className="cd-mb-[0.25rem]"
                      color="gray"
                      disabled={formProforma.values.payments.length === 1}
                      variant="light"
                      onClick={() => formProforma.removeListItem('payments', idx)}
                    >
                      <IconX />
                    </ActionIcon>
                  </div>
                ))}
                <div>
                  {formProforma.errors.payments && (
                    <Text c="red" size="sm">
                      {formProforma.errors.payments}
                    </Text>
                  )}
                </div>
                <div className="cd-flex cd-flex-col cd-gap-y-[0.5rem] md:cd-flex-row md:cd-gap-x-[1rem] md:cd-justify-end">
                  <Button
                    className="cd-mt-[1rem]"
                    color="blue"
                    onClick={() => {
                      formProforma.insertListItem('payments', {
                        percentage: 0,
                        text: '',
                      })
                    }}
                  >
                    Agregar pago
                  </Button>
                </div>
              </Fieldset>

              <Fieldset className="cd-flex cd-flex-col cd-gap-y-[0.5rem]" legend="Extras">
                <div className="cd-flex cd-gap-x-[1rem] cd-gap-y-[0.5rem] md:cd-gap-y-[1rem]">
                  <div className="cd-basis-[100%] md:cd-basis-1/2 cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
                    <Input
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label="Oferta válida hasta"
                      placeholder="Ingrese la validez"
                      typeInput="number"
                      {...formProforma.getInputProps('validUntil.amount')}
                      onBlur={() => formProforma.validateField('validUntil.amount')}
                    />
                    <Input
                      searchable
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      clearable={false}
                      data={buildSelectOptions({
                        data: Object.values(DueDateOptions),
                        label: 'label',
                        value: 'value',
                      })}
                      filter={(input) =>
                        filterSelect({
                          limit: 5,
                          search: input.search,
                          options: input.options,
                        })
                      }
                      label="Unidad"
                      placeholder="Seleccione la unidad"
                      typeInput="select"
                      {...formProforma.getInputProps('validUntil.unit')}
                      onBlur={() => formProforma.validateField('validUntil.unit')}
                    />
                  </div>
                  <div className="cd-basis-[100%] md:cd-basis-1/2 cd-flex cd-flex-col cd-gap-[1rem] md:cd-flex-row">
                    <Input
                      className="cd-basis-[100%] md:cd-basis-[70%]"
                      label="Fecha de entrega"
                      placeholder="Ingrese la fecha de entrega"
                      typeInput="number"
                      {...formProforma.getInputProps('dueDate.amount')}
                      onBlur={() => formProforma.validateField('dueDate')}
                    />
                    <Input
                      searchable
                      className="cd-basis-[100%] md:cd-basis-[30%]"
                      clearable={false}
                      data={buildSelectOptions({
                        data: Object.values(DueDateOptions),
                        label: 'label',
                        value: 'value',
                      })}
                      filter={(input) =>
                        filterSelect({
                          limit: 5,
                          search: input.search,
                          options: input.options,
                        })
                      }
                      label="Unidad"
                      placeholder="Seleccione la unidad"
                      typeInput="select"
                      {...formProforma.getInputProps('dueDate.unit')}
                      onBlur={() => formProforma.validateField('dueDate')}
                    />
                  </div>
                </div>
                <Input
                  autosize
                  className="cd-basis-[100%] md:cd-basis-1/3"
                  label="Garantía"
                  maxRows={6}
                  minRows={4}
                  placeholder="Describa la garantía"
                  typeInput="textarea"
                  {...formProforma.getInputProps('warranty')}
                  onBlur={() => formProforma.validateField('warranty')}
                />

                <Suspense>
                  <TextEditor
                    editor={editorDescription}
                    label="Descripción"
                    onChange={() => {
                      formProforma.setDirty({
                        description: true,
                      })
                    }}
                  />
                </Suspense>
              </Fieldset>
            </div>
          </form>
        </Container>
      )}
      {isLoading && (
        <LoaderText>
          <Text c="dimmed" className="cd-text-base">
            Cargando proforma
          </Text>
        </LoaderText>
      )}
      <ConfirmationModal
        cancelColor="gray"
        cancelText="Cancelar"
        confirmColor="red"
        confirmText="Descartar"
        opened={confirmExit}
        title="Descartar cambios"
        onCancel={() => setConfirmExit(false)}
        onConfirm={() => {
          setConfirmExit(false)
          navigate(-1)
        }}
      >
        <Text className="cd-text-base">
          Has realizado cambios en el formulario, ¿Deseas cerrarlo y descartar los cambios?
        </Text>
      </ConfirmationModal>
    </React.Fragment>
  )

  function buildContextMenu(item: ProformaBaseType['items'][0], idx: number) {
    return (
      <Menu.Dropdown key={`menu-context-${idx}`}>
        <Menu.Label>Opciones</Menu.Label>
        <Menu.Item
          onClick={() => {
            addAccessoryItem(item, idx)
          }}
        >
          Añadir accesorios
        </Menu.Item>
      </Menu.Dropdown>
    )
  }

  function buildExtraRows() {
    return (
      <React.Fragment key="extra-fragment">
        <Table.Tr key="search-row">
          <Table.Td colSpan={8}>
            <Input
              clearable
              searchable
              data={buildSelectOptions({
                data: Object.values(sourceDataQuery.data ?? {}),
                label: 'name',
                value: 'uid',
                groupBy: 'type',
                groupMap: Entities,
                disabledKey: 'disabled',
              })}
              disabled={isLoadingSourceData || isLoadingItems}
              filter={(input) =>
                filterSelect({
                  limit: 10,
                  search: input.search,
                  options: input.options,
                })
              }
              loading={isLoadingSourceData || isLoadingItems}
              nothingFoundMessage="No se encontraron item"
              placeholder="Buscar item por nombre"
              readOnly={isLoadingSourceData}
              typeInput="select"
              {...itemSearchInputProps}
              value={itemSearch}
              onChange={(value) => handleSearchChange(value)}
            />
          </Table.Td>
        </Table.Tr>
        <Table.Tr key="subtotal-row">
          <Table.Td colSpan={6} rowSpan={3} />
          <Table.Td className="cd-font-bold">Subtotal</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[0] ?? 0} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr key="iva-row">
          <Table.Td className="cd-font-bold">IVA ({formProforma.values.iva}%)</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[1] ?? 0} />
          </Table.Td>
        </Table.Tr>
        <Table.Tr key="total-row">
          <Table.Td className="cd-font-bold">Total</Table.Td>
          <Table.Td className="cd-font-bold cd-text-right">
            <NumberFormat value={totalProformaQuery.data?.[2] ?? 0} />
          </Table.Td>
        </Table.Tr>
      </React.Fragment>
    )
  }

  function addAccessoryItem(item: ProformaBaseType['items'][0], index: number) {
    if (item.type === 'accessory') return

    const newAccessory: AccessoryItemType = {
      uid: uuidv4(),
      type: 'accessory',
      name: `Accesorio para ${item.name}`,
      description: '',
      quantity: 20,
      uidItem: item.uid,
    }

    formProforma.insertListItem('items', newAccessory, index + 1)
  }

  function buildColumns() {
    return [
      {
        key: 'uid',
        label: 'ID',
        type: 'calc',
        width: '10%',
        defaultOnClick: true,
        render: ({ uid, type }) => (type !== 'accessory' ? uid : ''),
      },
      {
        key: 'type',
        label: 'Tipo',
        type: 'calc',
        width: '7%',
        defaultOnClick: true,
        render: ({ type }) => Entities[type].label,
      },
      {
        key: 'name',
        label: 'Nombre',
        width: '10%',
        type: 'calc',
        defaultOnClick: true,
        render: ({ name }) => name,
      },
      {
        key: 'description',
        label: 'Descripción',
        width: '20%',
        type: 'calc',
        hideMenu: true,
        render: (item, index) =>
          item.type !== 'accessory' ? (
            getDescription({
              item,
              index,
              handleInput: handleValueInput,
            })
          ) : (
            <Input
              autosize
              className="cd-basis-[100%]"
              maxRows={4}
              minRows={2}
              placeholder="Describa el accesorio"
              typeInput="textarea"
              value={item.description}
              onChange={(e) => handleItemInput('description', e.target.value, item.uid)}
            />
          ),
      },
      {
        key: 'quantity',
        label: 'Cantidad',
        width: '15%',
        type: 'number',
        inputProps: {
          min: 0,
          allowNegative: false,
          defaultValue: ({ quantity }) => quantity ?? 1,
          onChange: handleItemInput.bind(null, 'quantity'),
          suffix: ({ type }) => (type !== 'accessory' ? '' : '%'),
        },
      },
      {
        key: 'value',
        label: 'P. Unitario',
        width: '15%',
        type: 'calc',
        align: 'right',
        render: (item) => (
          <NumberFormat
            value={getProformaTotal({
              itemsProforma: itemsForm,
              unit: true,
              item,
            })}
          />
        ),
      },
      {
        key: 'total',
        label: 'Total',
        width: '15%',
        type: 'calc',
        align: 'right',
        render: (item) => (
          <NumberFormat
            value={getProformaTotal({
              itemsProforma: itemsForm,
              item,
            })}
          />
        ),
      },
    ] as GenericColumnType<ProformaBaseType['items'][0]>[]
  }

  function handleSearchChange(value: string | null) {
    setItemSearch(value)

    if (!value) return

    const item = sourceDataQuery.data?.[value]

    if (!item) return

    formProforma.insertListItem('items', { ...item })

    setItemSearch(null)
  }

  function handleItemInput(
    key: keyof ProformaBaseType['items'][0],
    value: number | string,
    uid: string,
  ) {
    const itemIdx = itemsForm.findIndex((item) => item.uid === uid)

    if (itemIdx === -1) return

    formProforma.setFieldValue(`items.${itemIdx}.${key}`, value, { forceUpdate: true })
  }

  function handleClientChange(value: string | null) {
    clientIDInputProps.onChange(value)
    setClientSearch(value)

    if (!value) {
      formProforma.setFieldValue('client', {
        uid: '',
        ruc: '',
        name: '',
        email: '',
        phone: '',
        location: '',
      })
      return
    }

    const client = clientsQuery.data?.find((item) => item.uid === value)

    if (!client) return

    formProforma.setFieldValue('client', {
      uid: client.uid,
      ruc: client.ruc,
      name: client.name,
      email: client.email,
      phone: client.phone,
      location: client.location,
    })
  }

  function handleValueInput(path: string, value: number | string) {
    formProforma.setFieldValue(path, value, { forceUpdate: true })
  }
}

export default ProformaForm
